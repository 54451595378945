import { getCertificates } from "@/services/certificate.service";

// action types
export const FETCH_CERTIFICATES = "fetchCertificates";

// mutation types
export const SET_CERTIFICATES = "setCertificates";

/**
 * @typedef {Object} State
 * @property {Certificate[]} certificates
 */

/** @type {State} */
const state = {
  certificates: null
};

const getters = {
  certificateSlugMap() {
    if (!state.certificates) return {};
    return state.certificates.reduce((map, certificate) => {
      map[certificate.slug] = certificate;
      return map;
    }, {});
  },
  certificateNameMap() {
    if (!state.certificates) return {};
    return state.certificates.reduce((map, certificate) => {
      map[certificate.name] = certificate;
      return map;
    }, {});
  },
  certificateFilterOptions() {
    if (!state.certificates) return [];
    return state.certificates.map(certificate => ({
      label: certificate.name,
      value: certificate.id
    }));
  }
};

const actions = {
  async [FETCH_CERTIFICATES](context) {
    if (state.certificates) return;
    const certificates = await getCertificates();
    context.commit(SET_CERTIFICATES, certificates);
  }
};

const mutations = {
  /**
   * @param {State} state 
   * @param {Certificate[]} certificates
   */
  [SET_CERTIFICATES](state, certificates) {
    state.certificates = certificates;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
