import ApiService from "./api.service";

/**
 * Retrieves labels
 * @returns {Promise<Label[]>} labels
 */
async function getLabels() {
  /** @type {{ data: { results: Label[] } }} */
  const {
    data: { results: labels }
  } = await ApiService.get("/v0/catalog/label");
  return labels;
}

function getLabelIcon(abbreviation) {
  return `https://projectcece.com/static/img/labels/${abbreviation}.png`;
}

export const LabelService = { getLabels, getLabelIcon };
