import ApiService from "./api.service";

export const DEAL_STATUS_PENDING = "pending";
export const DEAL_STATUS_REJECTED = "rejected";
export const DEAL_STATUS_EXPIRED = "expired";
export const DEAL_STATUS_ACTIVE = "active";

export const DEAL_STATUS_INVITE_SENT = "sent";
export const DEAL_STATUS_INVITE_RECEIVED = "received";

/**
 * Returns deal bootstrap color to use in UI
 */
export const getDealStatusVariant = status => {
  if (status === DEAL_STATUS_ACTIVE) {
    return "success";
  }
  if (status === DEAL_STATUS_INVITE_SENT ) {
    return "info";
  }
  if (status === DEAL_STATUS_PENDING || status === DEAL_STATUS_INVITE_RECEIVED) {
    return "info";
  }
  if (status === DEAL_STATUS_REJECTED) {
    return "warning-light";
  }
  if (status === DEAL_STATUS_EXPIRED) {
    return "secondary-light";
  }
  return "secondary"
};

/**
 * @type {any}
 */
export const getFullStatus = (status, initiated_by, me) => {
  if (status === DEAL_STATUS_PENDING) {
    if (me === initiated_by) {
      return DEAL_STATUS_INVITE_SENT;
    } else {
      return DEAL_STATUS_INVITE_RECEIVED;
    }
  }

  return status;
};

export const publisher = {
  /**
   * Create a deal between the current publisher and a store
   * @param {{ storeId: number, message?: string }} params
   * @returns {Promise<Deal>}
   */
  async createDeal({ storeId, message }) {
    if (!storeId) {
      throw new Error("publisher.createDeal error: storeId is not defined");
    }
    /** @type {{ data: Deal }} */
    const { data: deal } = await ApiService.post("/ib/forpublishers/deals/", {
      store: storeId,
      messages: message ? [{ text: message }] : undefined
      // requested_percentage: percentage
    });
    return deal;
  },

  /**
   * Returns current user's publisher deals
   * @returns {Promise<Deal[]>} Current publishers deals
   */
  async getMyDeals() {
    const {
      data: { results }
    } = await ApiService.getCached("ib/forpublishers/deals", 10 * 1000);
    return results;
  },

  /**
   * Respond to a deal
   * @param {number} dealId
   * @param {{ accept: boolean, reason?: string }} params
   * @returns {Promise<Deal>}
   */
  async respond(dealId, { accept, reason }) {
    const { data: deal } = await ApiService.patch(
      `ib/forpublishers/deals/${dealId}/respond/`,
      {
        accept,
        reason
      }
    );
    return deal;
  },

  /**
   * Add a message to a deal
   * @param {{ dealId: string, message: string }} params
   * @returns {Promise<Deal>}
   */
  async createMessage({ dealId, message }) {
    const { data: deal } = await ApiService.patch(
      `ib/forpublishers/deals/${dealId}/message/`,
      {
        text: message
      }
    );
    return deal;
  },

  async markMessageAsSeen({ messageId }) {
    const { data: message } = await ApiService.patch(
      `/ib/forpublishers/dealmessages/${messageId}/mark-as-seen/`
    );
    return message;
  }
};

export const store = {
  /**
   * Create a deal between the current publisher and a store
   * @param {{ publisherId: number, message?: string }} params
   * @returns {Promise<Deal>}
   */
  async createDeal({ publisherId, message }) {
    if (!publisherId) {
      throw new Error("store.createDeal error: publisherId is not defined");
    }
    /** @type {{ data: Deal }} */
    const { data: deal } = await ApiService.post("/ib/forshops/deals/", {
      publisher: publisherId,
      messages: message ? [{ text: message }] : undefined
      // requested_percentage: percentage
    });
    return deal;
  },

  /**
   * Returns current user's publisher deals
   * @returns {Promise<Deal[]>} Current publishers deals
   */
  async getMyDeals() {
    const {
      data: { results }
    } = await ApiService.getCached("ib/forshops/deals", 10 * 1000);
    return results;
  },

  /**
   * Respond to a deal
   * @param {number} dealId
   * @param {{ accept: boolean, reason?: string }} params
   * @returns {Promise<Deal>}
   */
  async respond(dealId, { accept, reason }) {
    const { data: deal } = await ApiService.patch(
      `ib/forshops/deals/${dealId}/respond/`,
      {
        accept,
        reason
      }
    );
    return deal;
  },

  /**
   * 
   * @param {number} dealId 
   * @param {object} patchData 
   * @returns {Promise<Deal>}
   */
  async updateDeal(dealId, patchData) {
    console.log(patchData);
    const { data: deal } = await ApiService.patch(
      `ib/forshops/deals/${dealId}/`, 
      patchData
    );
    return deal;
  },

  /**
   * Add a message to a deal
   * @param {{ dealId: string, message: string }} params
   * @returns {Promise<Deal>}
   */
  async createMessage({ dealId, message }) {
    const { data: deal } = await ApiService.patch(
      `ib/forshops/deals/${dealId}/message/`,
      {
        text: message
      }
    );
    return deal;
  },

  async markMessageAsSeen({ messageId }) {
    const { data: message } = await ApiService.patch(
      `/ib/forshops/dealmessages/${messageId}/mark-as-seen/`
    );
    return message;
  }
};
