import Vue from "vue";
import VueI18n from "vue-i18n";

// Localisation language list
import { locale as en } from "@/config/i18n/en";
import { locale as nl } from "@/config/i18n/nl";

// Detect browser language
// TODO: Disabled as long as we didn't translated everything yet
let language = null;
// let language = navigator.language || navigator.userLanguage;
// language = language ? language.split('-')[0] : null;

Vue.use(VueI18n);

let messages = {};
messages = { ...messages, en, nl };

// get current selected language
const lang = localStorage.getItem("language") || language || "en";

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages, // set locale messages
  numberFormats: {
    en: {
      currency: {
        style: "currency",
        currency: "EUR",
        notation: "standard"
      },
      percent: {
        style: "percent",
        useGrouping: false
      },
      decimal: {
        style: "decimal",
        useGrouping: true
      }
    },
    nl: {
      currency: {
        style: "currency",
        currency: "EUR",
        notation: "standard"
      },
      percent: {
        style: "percent",
        useGrouping: false
      },
      decimal: {
        style: "decimal",
        useGrouping: true
      }
    }
  },
  dateTimeFormats: {
    en: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric"
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric"
      }
    },
    nl: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric"
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric"
      }
    }
  }
});

export default i18n;
