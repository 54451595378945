import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import user from "./user.module";
import deal from "./deal.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import label from "./label.module";
import countrysite from "./countrysite.module";
import certificate from "./certificate.module";
import material from "./material.module";
import profileCompletion from "./profile-completion.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    user,
    deal,
    htmlClass,
    config,
    breadcrumbs,
    label,
    countrysite,
    certificate,
    material,
    profileCompletion
  }
});
