import i18n from "@/plugins/vue-i18n";

export const routes = [
  {
    path: "onboarding/",
    name: "store-onboarding-user",
    component: () =>
      import(
        /* webpackChunkName: "store-onboarding-user" */
        "@/pages/onboarding/User.vue"
      ),
    meta: {
      title: i18n.t("WEBSHOP.ONBOARDING.PAGE_TITLE")
    }
  },
  {
    path: "onboarding/store/",
    name: "store-onboarding-store",
    component: () =>
      import(
        /* webpackChunkName: "store-onboarding-store" */
        "@/pages/webshop/onboarding/Store.vue"
      ),
    meta: {
      title: i18n.t("WEBSHOP.ONBOARDING.PAGE_TITLE")
    }
  },
  {
    path: "onboarding/next-steps/",
    name: "store-onboarding-next-steps",
    component: () =>
      import(
        /* webpackChunkName: "store-onboarding-next-steps" */
        "@/pages/webshop/onboarding/NextSteps.vue"
      ),
    meta: {
      title: i18n.t("WEBSHOP.ONBOARDING.PAGE_TITLE")
    }
  },
  {
    path: "onboarding/analysis/",
    name: "store-impact-analysis",
    component: () =>
      import(
        /* webpackChunkName: "store-analysis" */
        "@/pages/webshop/impact-analysis/ImpactAnalysis.vue"
      ),
    meta: {
      title: i18n.t("WEBSHOP.ONBOARDING.PAGE_TITLE")
    }
  },
  {
    path: "onboarding/plan/",
    name: "store-choose-plan",
    component: () =>
      import(
        /* webpackChunkName: "store-choose-plan" */
        "@/pages/webshop/onboarding/ChoosePlan.vue"
      ),
    meta: {
      title: i18n.t("WEBSHOP.ONBOARDING.PAGE_TITLE")
    }
  },
  {
    path: "onboarding/install/",
    name: "store-install-fairpro",
    component: () =>
      import(
        /* webpackChunkName: "store-install-fairpro" */
        "@/pages/webshop/onboarding/InstallFairpro.vue"
      ),
    meta: {
      title: i18n.t("WEBSHOP.ONBOARDING.PAGE_TITLE")
    }
  },
  {
    path: "onboarding/settings/",
    name: "store-onboarding-settings",
    component: () =>
      import(
        /* webpackChunkName: "store-onboarding-settings" */
        "@/pages/webshop/settings/Affiliate.vue"
      ),
    meta: {
      title: i18n.t("WEBSHOP.ONBOARDING.PAGE_TITLE")
    }
  },
  {
    path: "onboarding/shipping/",
    name: "store-onboarding-shipping",
    component: () =>
      import(
        /* webpackChunkName: "store-onboarding-shipping" */
        "@/pages/webshop/settings/store/Store.vue"
      ),
    meta: {
      title: i18n.t("WEBSHOP.ONBOARDING.PAGE_TITLE")
    }
  },
  {
    path: "onboarding/share/",
    name: "store-share",
    component: () =>
      import(
        /* webpackChunkName: "store-share" */
        "@/pages/webshop/onboarding/ShareSocialStep.vue"
      ),
    meta: {
      title: i18n.t("WEBSHOP.ONBOARDING.PAGE_TITLE")
    }
  },
  {
    path: "dashboard/",
    name: "dashboard",
    component: () =>
      import(
        /* webpackChunkName: "store-dashboard" */
        "@/pages/webshop/dashboard/Dashboard.vue"
      ),
    meta: {
      title: i18n.t("PAGE_TITLES.PARTNERS")
    }
  },
  {
    path: "analytics/",
    name: "store-analytics",
    component: () =>
      import(
        /* webpackChunkName: "store-analytics" */
        "@/pages/webshop/analytics/Analytics.vue"
      ),
    meta: {
      title: i18n.t("WEBSHOP.ANALYTICS.PAGE_TITLE")
    }
  },
  {
    path: "settings/",
    name: "settings",
    redirect: "settings/affiliate/",
    component: {
      render: c => c("router-view")
    },
    meta: {
      title: i18n.t("PAGE_TITLES.SETTINGS")
    },
    children: [
      {
        path: "affiliate/",
        name: "settings-affiliate",
        component: () =>
          import(
            /* webpackChunkName: "store-settings-affiliate" */
            "@/pages/webshop/settings/Affiliate.vue"
          ),
        meta: {
          title: i18n.t("PAGE_TITLES.AFFILIATE_SETTINGS")
        }
      },
      {
        path: "user/",
        name: "settings-user",
        component: () =>
          import(
            /* webpackChunkName: "store-settings-user" */
            "@/pages/webshop/settings/UserAccountSettings.vue"
          ),
        meta: {
          title: i18n.t("PAGE_TITLES.USER_SETTINGS")
        }
      },
      {
        path: "store/",
        name: "settings-store",
        component: () =>
          import(
            /* webpackChunkName: "store-settings-store" */
            "@/pages/webshop/settings/store/Store.vue"
          ),
        meta: {
          title: i18n.t("PAGE_TITLES.STORE_SETTINGS")
        }
      },
      {
        path: "plan/",
        name: "settings-plan",
        component: () =>
          import(
            /* webpackChunkName: "store-settings-plan" */
            "@/pages/webshop/settings/MembershipPlan.vue"
          ),
        meta: {
          title: i18n.t("PAGE_TITLES.STORE_SETTINGS_UPGRADE")
        }
      },
      {
        path: "change-password/",
        name: "change-password",
        component: () =>
          import(
            /* webpackChunkName: "store-change-password" */
            "@/pages/webshop/settings/PasswordChange.vue"
          ),
        meta: {
          title: i18n.t("PAGE_TITLES.PW_CHANGE")
        }
      },
      {
        path: "setup-payment/",
        name: "setup-payment",
        component: () =>
          import(
            /* webpackChunkName: "store-setup-payment" */
            "@/pages/webshop/settings/RedirectToPaymentSetup.vue"
          ),
        meta: {
          layout: "Empty",
          title: i18n.t("PAGE_TITLES.PW_CHANGE")
        }
      },
      {
        path: "finish-setup-payment/",
        name: "finish-setup-payment",
        component: () =>
          import(
            /* webpackChunkName: "store-setup-payment" */
            "@/pages/webshop/settings/RedirectFromPaymentSetup.vue"
          ),
        meta: {
          layout: "Empty",
          title: i18n.t("PAGE_TITLES.PW_CHANGE")
        }
      }
    ]
  },
  {
    path: "finance/",
    name: "finance",
    redirect: "finance/sales",
    component: {
      render: c => c("router-view")
    },
    children: [
      {
        path: "sales/",
        name: "sales",
        component: () =>
          import(
            /* webpackChunkName: "store-sales" */
            "@/pages/webshop/finance/Sales.vue"
          ),
        meta: {
          title: i18n.t("PAGE_TITLES.SALES")
        }
      },
      {
        path: "invoices/",
        name: "invoices",
        component: () =>
          import(
            /* webpackChunkName: "store-invoices" */
            "@/pages/webshop/finance/Invoices.vue"
          ),
        meta: {
          title: i18n.t("PAGE_TITLES.INVOICES")
        }
      },
      {
        path: "invoice-wizard/:proformaId/:step/",
        name: "invoice-wizard",
        component: () =>
          import(
            /* webpackChunkName: "store-invoices-payment-wizard" */
            "@/pages/webshop/finance/PaymentWizard.vue"
          ),
        meta: {
          title: i18n.t("PAGE_TITLES.INVOICE_WIZARD")
        }
      },
      {
        path: "invoice-wizard/:proformaId/payment-success/:invoiceId/",
        name: "payment-success",
        component: () =>
          import(
            /* webpackChunkName: "store-invoices-payment-success" */
            "@/pages/webshop/finance/PaymentSuccess.vue"
          ),
        meta: {
          title: i18n.t("PAGE_TITLES.PAYMENT_SUCCESS")
        }
      },
      {
        path: "invoice-confirmed/:proformaId/:securityHash",
        name: "publicInvoiceConfirmed",
        component: () =>
          import(
            /* webpackChunkName: "store-invoices-confirmed" */
            "@/pages/webshop/finance/InvoiceConfirmed.vue"
          ),
        meta: {
          layout: "Empty",
          public: true
        }
      },
      {
        path: "invoice-payment/:invoiceId/",
        name: "invoice-payment",
        component: () =>
          import(
            /* webpackChunkName: "invoice-payment" */
            "@/pages/webshop/finance/InvoicePayment.vue"
          ),
        meta: {
          title: "Invoice payment"
        }
      },
      {
        path: "invoice-payment/:invoiceId/payment-success/",
        name: "invoice-payment-success",
        component: () =>
          import(
            /* webpackChunkName: "invoice-payment-success" */
            "@/pages/webshop/finance/InvoicePayment.vue"
          ),
        meta: {
          title: "Invoice payment"
        }
      }
    ]
  },
  {
    path: "affiliate/",
    name: "affiliate",
    redirect: "affiliate/my-publishers",
    component: {
      render: c => c("router-view")
    },
    children: [
      {
        path: "brand-profile/",
        name: "webshop-brand-profile",
        component: () =>
          import(
            /* webpackChunkName: "store-my-publishers" */
            "@/pages/webshop/affiliate/Profile.vue"
          ),
        meta: {
          title: "My profile | Impactbytes"
        }
      },
      {
        path: "my-publishers/",
        name: "webshop-my-publishers",
        component: () =>
          import(
            /* webpackChunkName: "store-my-publishers" */
            "@/pages/webshop/affiliate/my-publishers/MyPublishers.vue"
          ),
        meta: {
          title: i18n.t("PAGE_TITLES.WEBSHOP_MY_PUBLISHERS")
        }
      },
      {
        path: "my-publishers/invite",
        name: "webshop-my-publishers-invite",
        component: () =>
          import("@/pages/webshop/affiliate/my-publishers/Invite.vue"),
        meta: {
          title: i18n.t("PAGE_TITLES.WEBSHOP_MY_PUBLISHERS")
        }
      },
      {
        path: "publishers/",
        name: "webshop-publishers",
        component: () =>
          import(
            /* webpackChunkName: "store-publishers-overview" */
            "@/pages/webshop/affiliate/PublishersOverview.vue"
          ),
        meta: {
          title: i18n.t("PAGE_TITLES.WEBSHOP_PUBLISHERS")
        }
      },
      {
        path: "publishers/:slug",
        name: "webshop-publishers-detail",
        component: () =>
          import(
            /* webpackChunkName: "store-publisher-detail" */
            "@/pages/webshop/affiliate/Publisher.vue"
          ),
        meta: {
          title: i18n.t("PAGE_TITLES.WEBSHOP_PUBLISHER")
        }
      },
      {
        path: "feeds/",
        name: "webshop-data-feeds",
        component: () => import("@/pages/webshop/affiliate/DataFeeds.vue"),
        meta: {
          title: i18n.t("PAGE_TITLES.WEBSHOP_DATA_FEEDS")
        }
      }
    ]
  },
  {
    path: "giftcard/",
    name: "giftcard",
    redirect: "giftcard/transactions",
    component: {
      render: c => c("router-view")
    },
    children: [
      {
        path: "transactions/",
        name: "ffgc-transactions",
        component: () =>
          import(
            /* webpackChunkName: "store-giftcard-transactions" */
            "@/pages/webshop/ffgc/Transactions.vue"
          ),
        meta: {
          title: i18n.t("PAGE_TITLES.FFGC_TRANSACTIONS")
        }
      },
      {
        path: "payments/",
        name: "ffgc-payments",
        component: () =>
          import(
            /* webpackChunkName: "store-giftcard-payments" */
            "@/pages/webshop/ffgc/Payments.vue"
          ),
        meta: {
          title: i18n.t("PAGE_TITLES.FFGC_PAYMENTS")
        }
      }
    ]
  },
  // {
  //   path: "google-shopping/feeds/",
  //   name: "google-shopping",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "google-feeds" */
  //       "@/pages/webshop/google/GoogleShopping.vue"
  //     ),
  //   meta: {
  //     title: "Google Shopping Feed"
  //   }
  // },
  {
    path: "impact-analysis/",
    name: "store-impact-analysis",
    component: () =>
      import(
        /* webpackChunkName: "store-analysis" */
        "@/pages/webshop/impact-analysis/ImpactAnalysis.vue"
      ),
    meta: {
      title: i18n.t("WEBSHOP.ONBOARDING.PAGE_TITLE")
    }
  },
  {
    path: "*",
    name: "webshop-404",
    component: () =>
      import(
        /* webpackChunkName: "store-404" */
        "@/pages/webshop/404.vue"
      ),
    meta: {
      title: "404 Page Not Found" // TODO: translate
    }
  }
];
