import config from "@/config";
import ApiService from "@/services/api.service";
import { UserService } from "@/services/user.service";
import {
  getMyStore,
  createMyStore,
  submitImpactAnalysis,
  createMembershipPlan
} from "@/services/store.service";
import {
  getMyPublisher,
  createMyPublisher
} from "@/services/publisher.service";
import i18nService from "@/services/i18n.service";
import { GlobalEventBus } from "@/plugins/global-event-bus";
import { SET_ERROR } from "./auth.module";
import { parseLocalStorage } from "@/lib/localstorage";
import i18n from "@/plugins/vue-i18n";

// user roles
export const ROLE_WEBSHOP = 2;
export const ROLE_PUBLISHER = 4;

// store memberships
export const MEMBERSHIP_FREE = 3;
export const MEMBERSHIP_BASIC = 0;
export const MEMBERSHIP_PREMIUM = 1;
export const MEMBERSHIP_PLATINUM = 2;
export const MEMBERSHIP_PLAN_UNLIMITED = "unlimited";
export const MEMBERSHIP_TERM_MONTH = 0;
export const MEMBERSHIP_TERM_YEAR = 1;

// action types
export const CREATE_PUBLISHER_USER = "createPublisher";
export const CREATE_STORE_USER = "createStore";
export const UPDATE_ME = "updateUser";
export const CREATE_MY_STORE = "createMyStore";
export const GET_STORE_DATA = "getStoreData";
export const GET_PUBLISHER_DATA = "getPublisherData";
export const GET_ME = "getMe";
export const ADD_PUBLISHER_DATA = "addPublisherData";
export const CONFIRM_EMAIL_ADDRESS = "confirmEmailAddress";
export const UPDATE_STORE_DATA = "updateStoreData";
export const ADD_MEMBERSHIP_PLAN = "addMembershipPlan";
export const SUBMIT_IMPACT_ANALYSIS = "submitImpactAnalysis";
export const CLEAR = "userModuleClear";

// mutation types
export const SET_STORE_DATA = "setStoreData";
export const SET_PUBLISHER_DATA = "setPublisherData";
export const SET_USER_DATA = "setUserData";
export const SET_CHECK_IS_APPROVED_INTERVAL = "setCheckIsApprovedInterval";
export const CLEAR_STORAGE = "userModuleClearStorage";

/**
 * @typedef {Object} UserState
 * @property {number} id
 * @property {string} email
 * @property {string} firstName
 * @property {string} lastName
 * @property {number} role
 * @property {boolean} isEmailConfirmed
 */

/**
 * @typedef {Object} StoreState
 * @property {number} id
 * @property {string} name
 * @property {string} email
 * @property {StoreMembership} membership
 * @property {boolean} allowNewDeal
 * @property {string} affiliateVersion
 * @property {boolean} show_on_site
 * @property {Date} status_code_implemented
 * @property {Date} status_contract_signed
 * @property {MembershipPlan} membership_plan
 * @property {Date} status_impact_analysis
 * @property {Date} status_impact_approved
 * @property {Date} status_social
 * @property {Number} store_type
 * @property {Number} affiliate_id
 * @property {Boolean} automatic_payments
 * @property {Boolean} is_ffg_partner
 * @property {Boolean} is_impact_analysis_partner
 */

/**
 * @typedef {Object} PublisherState
 * @property {number} id
 * @property {string} name
 * @property {string} email
 * @property {string} logo
 * @property {string} short_description
 * @property {string} long_description
 * @property {boolean} is_approved
 * @property {string[]} media
 * @property {string[]} topics
 * @property {string[]} target_audience
 */

/**
 * @typedef {Object} State
 * @property {UserState} user
 * @property {StoreState} store
 * @property {PublisherState} publisher
 * @property {any} checkIsApprovedInterval
 */

/** @type {State} */
const state = {
  user: parseLocalStorage("user") || {
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    role: null,
    isEmailConfirmed: null
  },
  store: parseLocalStorage("store") || {
    id: null,
    name: null,
    email: null,
    membership: null,
    allowNewDeal: false,
    affiliateVersion: null,
    show_on_site: false,
    status_code_implemented: null,
    status_contract_signed: null,
    status_impact_analysis: null,
    status_impact_approved: null,
    status_social: null,
    membership_plan: null,
    affiliate_id: null,
    automatic_payments: null,
    is_ffg_partner: null,
    is_impact_analysis_partner: null
  },
  publisher: parseLocalStorage("publisher") || {
    id: null,
    name: null,
    email: null,
    logo: null,
    short_description: null,
    long_description: null,
    target_audience: null,
    media: null,
    topics: null,
    is_approved: false,
    total_min_reach: null
  },
  checkIsApprovedInterval: null
};

/** @type {import("vuex").GetterTree<State>} */
const getters = {
  currentUserID() {
    return state.user.id;
  },
  currentStoreID() {
    return state.store.id;
  },
  currentStoreName() {
    return state.store.name;
  },
  isWebshopUser() {
    return state.user.role === ROLE_WEBSHOP;
  },
  isPublisherUser() {
    return state.user.role === ROLE_PUBLISHER;
  },
  hasCompleteStoreProfile() {
    return (
      state.store.id &&
      state.store.name &&
      state.store.email &&
      (state.store.status_code_implemented || state.store.show_on_site)
    );
  },
  hasPublisherData() {
    return state.publisher.id && state.publisher.name && state.publisher.email;
  },
  isUserApproved() {
    if (state.user.role === ROLE_WEBSHOP) {
      return state.store.show_on_site;
    } else {
      return state.publisher.is_approved;
    }
  },
  isFreeStore() {
    return state.store.membership === MEMBERSHIP_FREE;
  },
  isBasicStore() {
    return state.store.membership === MEMBERSHIP_BASIC;
  },
  isPremiumStore() {
    return state.store.membership === MEMBERSHIP_PREMIUM;
  },
  isPlatinumStore() {
    return state.store.membership === MEMBERSHIP_PLATINUM;
  },
  hasAffiliateVersionSmallerThan3() {
    if (state.store.affiliateVersion) {
      const mainVersion = state.store.affiliateVersion.substring(0, 1);
      return mainVersion === "1" || mainVersion === "2";
    }
    return false;
  },
  hasMembershipPlan() {
    return (state.store.membership_plan !== null);
  },
  getMembershipPlan() {
    return state.store.membership_plan;
  },
  finishedOnboarding() {
    if (state.store.status_social !== null) {
      return true;
    }
    // Stores on free plan already have an active plan. Should still finish
    // code implementation + invoicing info
    if (state.store.membership === MEMBERSHIP_FREE) {
      if (state.store.status_code_implemented && state.store.show_on_site) {
        return true;
      }
      return false;
    }
    // Stores not on the free plan are regarded as done with onboarding
    // once their plan is activated
    if (state.store.membership_plan !== null) {
      if (state.store.membership_plan.active === true) {
        return true;
      } else{
        return false;
      }
    }
    return true;
  },
  isAllowedNewDeals() {
    return state.store.allowNewDeal;
  },
  invitedByPublisher() {
    var ref = window.localStorage.getItem("referrer");
    if (ref) {
      if (ref.indexOf("publisher_invite_id:") > -1) {
        return true;
      }
    }
    return false;
  },
  isFFGPartner() {
    return state.store.is_ffg_partner;
  }
};

/** @type {import("vuex").ActionTree<State>} */
const actions = {
  [CREATE_PUBLISHER_USER](_context, data) {
    return UserService.createPublisherUser(data);
  },
  [CREATE_STORE_USER](_context, data) {
    return UserService.createStoreUser(data);
  },
  async [UPDATE_ME](context, { firstName, lastName }) {
    const { data: user } = await UserService.updateMe({ firstName, lastName });
    context.commit(SET_USER_DATA, {
      id: user.id,
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      role: user.role,
      isEmailConfirmed: user.is_email_confirmed
    });
    return user;
  },
  async [GET_ME](context) {
    try {
      // Fetch general user data and commit to store
      const {
        data: {
          id,
          email,
          first_name: firstName,
          last_name: lastName,
          role,
          is_email_confirmed: isEmailConfirmed
        }
      } = await UserService.me();
      context.commit(SET_USER_DATA, {
        id,
        email,
        firstName,
        lastName,
        role,
        isEmailConfirmed
      });
    } catch (err) {
      console.error("GET_ME error:", err);
    }
  },
  async [GET_STORE_DATA](context, fromLogin = false) {
    try {
      // Gets the Store user data and stores the storename
      // and store ID in local storage
      const store = await getMyStore();

      // TODO: Write field to localstorage that shows if store
      // is being onboarded through a publisher invite
      if (fromLogin) {
        if (store.invited_by) {
          const invite_value = "publisher_invite_id:" + store.invited_by;
          window.localStorage.setItem("referrer", invite_value);
        }
      }

      // Only set the language from user preferences after logging in.
      if (fromLogin) {
        const userLanguageCode =
          store.notification_language === 0 ? "nl" : "en";
        i18nService.setActiveLanguage(userLanguageCode);
        GlobalEventBus.$emit("user-lang-set", userLanguageCode);
      }

      context.commit(SET_STORE_DATA, store);
      return store;
    } catch (err) {
      console.error("GET_STORE_DATA error:", err);
      if (err.response && err.response.data && err.response.data.errors) {
        context.commit(SET_ERROR, err.response.data.errors);
      }
    }
  },
  async [GET_PUBLISHER_DATA](context) {
    try {
      const {
        id,
        name,
        email,
        logo,
        short_description,
        long_description,
        media,
        topics,
        target_audience,
        is_approved,
        total_min_reach
      } = await getMyPublisher();

      context.commit(SET_PUBLISHER_DATA, {
        id,
        name,
        email,
        logo,
        short_description,
        long_description,
        is_approved,
        media,
        topics,
        target_audience,
        total_min_reach
      });

      // If the publisher is not yet approved, and there is no active interval yet
      // start an interval to check the publishers profile every 30 seconds
      // if the publisher account is approved already
      if (!is_approved && !context.state.checkIsApprovedInterval) {
        context.commit(
          SET_CHECK_IS_APPROVED_INTERVAL,
          setInterval(() => {
            context.dispatch(GET_PUBLISHER_DATA);
          }, 30 * 1000)
        );
      } else if (is_approved && context.state.checkIsApprovedInterval) {
        clearInterval(context.state.checkIsApprovedInterval);
        context.commit(SET_CHECK_IS_APPROVED_INTERVAL, null);
      }
    } catch (err) {
      console.error("GET_PUBLISHER_DATA error", err);
      if (err.response && err.response.data && err.response.data.errors) {
        context.commit(SET_ERROR, err.response.data.errors);
      } else {
        context.commit(SET_ERROR, [i18n.t("GENERAL.GENERAL_ERROR")]);
      }
    }
  },
  [UPDATE_STORE_DATA](context, payload) {
    return ApiService.patch(
      `/ib/forshops/store/${context.getters.currentStoreID}/`,
      payload
    )
      .then(response => {
        // Store new user data in localstorage
        context.commit(SET_STORE_DATA, response.data);
      })
      .catch(err => {
        console.error("UPDATE_STORE_DATA error:", err, err.response);
      });
  },
  async [ADD_PUBLISHER_DATA](
    context,
    { name, logo, email, notifications, link, shortDescription, storeInvite, referrer }
  ) {
    const { data } = await createMyPublisher({
      name,
      logo,
      email,
      notifications,
      link,
      short_description: shortDescription,
      store_invite: storeInvite,
      referrer: referrer
    });
    context.commit(SET_PUBLISHER_DATA, {
      id: data.id,
      name: data.name,
      email: data.email,
      logo: data.logo,
      short_description: data.short_description
    });
  },
  async [CREATE_MY_STORE](
    context,
    { store_name, store_url, email, store_category, store_type, framework, other_framework }
  ) {
    const result = await createMyStore({
      store_name,
      store_url,
      affiliate: { email },
      store_category,
      store_type,
      framework,
      other_framework
    });
    context.commit(SET_STORE_DATA, {
      id: result.id,
      store_name: result.store_name,
      store_type: result.store_type,
      affiliate: result.affiliate,
      membership: result.membership
    });
  },
  async [CONFIRM_EMAIL_ADDRESS](context, { uid, token }) {
    try {
      const { data } = await UserService.confirmEmailAddress({ uid, token });
      if (data && data.status) {
        context.commit(SET_USER_DATA, {
          ...state.user,
          isEmailConfirmed: true
        });
        return true;
      }
      return false;
    } catch (err) {
      console.error("Error", err.response);
    }
  },
  async [ADD_MEMBERSHIP_PLAN](context, { membership, term, no_start_date }) {
    const store = await createMembershipPlan(membership, term, no_start_date);
    context.commit(SET_STORE_DATA, store);
    return store;
  },
  async [SUBMIT_IMPACT_ANALYSIS](context) {
    const store = await submitImpactAnalysis();
    context.commit(SET_STORE_DATA, store);
  },
  [CLEAR](context) {
    if (context.state.checkIsApprovedInterval) {
      clearInterval(context.state.checkIsApprovedInterval);
      context.commit(SET_CHECK_IS_APPROVED_INTERVAL, null);
    }
    context.commit(CLEAR_STORAGE);
  }
};

/** @type {import("vuex").MutationTree<State>} */
const mutations = {
  [SET_STORE_DATA](
    state,
    {
      id,
      store_name,
      store_type,
      affiliate: { email, allow_new_deal, version, affiliate_id, automatic_payments },
      membership,
      show_on_site,
      status_code_implemented,
      status_contract_signed,
      membership_plan,
      status_impact_analysis,
      status_impact_approved,
      status_social,
      giftcard_partner,
      store_category
    }
  ) {
    state.store.id = id || state.store.id;
    state.store.name = store_name || state.store.name;

    state.store.store_type =
      store_type !== undefined ? store_type : state.store.store_type;

    state.store.email = email || state.store.email;

    state.store.allowNewDeal =
      allow_new_deal !== undefined ? allow_new_deal : state.store.allowNewDeal;

    state.store.membership =
      membership !== undefined ? membership : state.store.membership;

    state.store.affiliateVersion = version || state.store.affiliateVersion;

    state.store.show_on_site =
      show_on_site !== undefined ? show_on_site : state.store.show_on_site;

    state.store.status_code_implemented = status_code_implemented
      ? new Date(status_code_implemented)
      : state.store.status_code_implemented;

    state.store.status_contract_signed = status_contract_signed
      ? new Date(status_contract_signed)
      : state.store.status_contract_signed;

    state.store.membership_plan =
      membership_plan !== undefined
        ? membership_plan
        : state.store.membership_plan;

    state.store.status_impact_analysis = status_impact_analysis
      ? new Date(status_impact_analysis)
      : state.store.status_impact_analysis;

    state.store.status_impact_approved = status_impact_approved
      ? new Date(status_impact_approved)
      : state.store.status_impact_approved;

    state.store.status_social = status_social
      ? new Date(status_social)
      : state.store.status_social;
      
    state.store.affiliate_id = affiliate_id || state.store.affiliate_id;

    state.store.automatic_payments = automatic_payments !== undefined ? automatic_payments : state.store.automatic_payments;

    state.store.is_ffg_partner = giftcard_partner ? true : false;

    console.log(store_category);

    if (store_category != undefined) {
      if ( store_category.length === 0 || store_category.some(item => config.impactCategories.includes(item))) {
        state.store.is_impact_analysis_partner = true;
      } else {
        state.store.is_impact_analysis_partner = false;
      }
    } 
    console.log(state.store.is_impact_analysis_partner);
    
    window.localStorage.setItem("store", JSON.stringify(state.store));
  },
  [SET_PUBLISHER_DATA](state, data) {
    state.publisher = Object.assign({}, state.publisher, data);
    window.localStorage.setItem("publisher", JSON.stringify(state.publisher));
  },
  [SET_USER_DATA](state, { id, email, firstName, lastName, role, isEmailConfirmed }) {
    state.user.id = id;
    state.user.email = email;
    state.user.firstName = firstName;
    state.user.lastName = lastName;
    state.user.role = role;
    state.user.isEmailConfirmed = isEmailConfirmed;
    window.localStorage.setItem("user", JSON.stringify(state.user));
  },
  [SET_CHECK_IS_APPROVED_INTERVAL](state, value) {
    state.checkIsApprovedInterval = value;
  },
  [CLEAR_STORAGE]() {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("store");
    window.localStorage.removeItem("publisher");
    window.localStorage.removeItem("invite-store");
    window.localStorage.removeItem("invite-publisher");
    window.localStorage.removeItem("has-zones");
    window.localStorage.removeItem("referrer");
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
