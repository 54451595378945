import ApiService from "./api.service";

/**
 * Retrieves certificates
 * @returns {Promise<Certificate[]>} with certificates
 */
export async function getCertificates() {
  /** @type {{ data: { results: Certificate[] } }} */
  const {
    data: { results: certificates }
  } = await ApiService.get("/ib/forpublishers/certificates");
  return certificates;
}
