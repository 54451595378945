const cache = {};

function get(key) {
  if (!cache[key]) return null;
  if (Date.now() - cache[key].timestamp > cache[key].timeout) {
    return null;
  }
  return cache[key].data;
}

function set(key, data, timeout) {
  cache[key] = { timestamp: Date.now(), data, timeout };
}

export default { get, set };
