export default {
  api: {
    baseURL: "https://www.projectcece.com/api"
  },
  typesense: {
    storeCollection: "production_Store",
    publisherCollection: "production_Publisher",
    productCollection: "production_Product",
    apiKey: "DsXwPw74N8x2Cu50cQdrfFJusAe7uZme" // store-dashboard production search key
  },
  impactbytesUrls: {
    app: "https://app.impactbytes.io"
  },
  featureFlags: {},
  jotform: {
    brandInfoFormId: "231583077748365",
    multiBrandInfoFormId: "223383466774062",
    materialFormId: "223053718495056",
    supplierFormId: "222981805960059"
  }
};
