import { getMaterials } from "@/services/material.service";

// action types
export const FETCH_MATERIALS = "fetchMaterials";

// mutation types
export const SET_MATERIALS = "setMaterials";

/**
 * @typedef {Object} State
 * @property {Material[]} materials
 */

/** @type {State} */
const state = {
  materials: null
};

const getters = {
  /**
   * @returns {Object<string, Material>}
   */
  materialMap() {
    if (!state.materials) return {};
    return state.materials.reduce((map, material) => {
      map[material.slug] = material;
      return map;
    }, {});
  },
  materialFilterOptions() {
    if (!state.materials) return [];
    return state.materials.map(material => ({
      label: material.name,
      value: material.id
    }));
  }
};

const actions = {
  async [FETCH_MATERIALS](context) {
    if (state.materials) return;
    const materials = await getMaterials();
    context.commit(SET_MATERIALS, materials);
  }
};

const mutations = {
  /**
   * @param {State} state
   * @param {Material[]} materials
   */
  [SET_MATERIALS](state, materials) {
    state.materials = materials;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
