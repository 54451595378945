import ApiService from "@/services/api.service";
import i18nService from "@/services/i18n.service";
import JwtService from "@/services/jwt.service";

import * as UserModule from "@/store/user.module";

import { GET_ME, GET_PUBLISHER_DATA, GET_STORE_DATA } from "./user.module";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REFRESH_ACCESS_TOKEN = "refreshAccessToken";
export const SETUP_SIB = "setupSib";

// mutation types
export const PURGE_AUTH = "logOut";
export const CLEAR_ERRORS = "clearErrors";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const UPDATE_AUTH = "refreshToken";

// The state items are only held on the currently loaded page.
// This is useful to for example prevent multiple refresh requests
// on the same page load.
const state = {
  errors: [],
  tokens: {}, // Holds the authentication tokens
  isAuthenticated: !!JwtService.getToken(),
  refreshTokenPromise: null // Holds the promise of the refresh token
};

// LocalStorage holds store ID, and store name
const getters = {
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, { email, password }) {
    return new Promise((resolve, reject) => {
      ApiService.post("/v1/token/", { email, password })
        .then(async response => {
          context.commit(SET_AUTH, response.data);

          // Get user data
          await context.dispatch(GET_ME);

          // Get user publisher/webshop data
          if (context.getters.isPublisherUser) {
            await context.dispatch(GET_PUBLISHER_DATA);
          } else if (context.getters.isWebshopUser) {
            await context.dispatch(GET_STORE_DATA, true);
          }

          resolve(response.data);
        })
        .catch(({ response }) => {
          var errors = [response.data.detail];
          context.commit(SET_ERROR, errors);
          reject(response.data.detail);
        });
    });
  },
  [LOGOUT](context) {
    // Clear auth tokens
    context.commit(PURGE_AUTH);
    // Clear user/store/publisher values in local storage
    context.dispatch(UserModule.CLEAR);
    // Clear active language in locale storage
    i18nService.clearActiveLanguage();
    // Refresh the page to make sure any vuex store values are gone
    window.location.href = "/login";
  },
  [REFRESH_ACCESS_TOKEN](context) {
    // If this is the first time the refreshToken has been called, make a request
    // otherwise return the same promise to the caller
    if (context.state.refreshTokenPromise) {
      return context.state.refreshTokenPromise;
    }
    const promise = ApiService.post(
      "/v1/token/refresh/",
      {
        refresh: JwtService.getRefreshToken()
      },
      { authorization: false }
    );
    context.commit("refreshTokenPromise", promise);

    // Wait for the Refresh token request to resolve. On success set the token and clear
    // promise. Clear the promise on error as well
    promise
      .then(response => {
        context.commit(UPDATE_AUTH, {
          accessToken: response.data.access,
          refreshToken: response.data.refresh
        });
        context.commit("refreshTokenPromise", null);

        return response.data.access;
      })
      .catch(response => {
        console.error(response);
        context.commit("refreshTokenPromise", null);
      });
  },
  // TODO: Need to have a seperate endpoint for verification,
  // because we don't want to ask for refresh token on
  // every page load.
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.post("/v1/token/refresh/", {
        refresh: JwtService.getRefreshToken()
      })
        .then(response => {
          context.commit(UPDATE_AUTH, {
            accessToken: response.data.access,
            refreshToken: response.data.refresh
          });
        })
        .catch(({ response }) => {
          console.error("Couldn't verify, with response: " + response);
          context.commit(SET_ERROR, response.data);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },

  // Setup Send In Blue for the logged in email address
  [SETUP_SIB](context, email) {
    if (email !== undefined && email !== null && email !== "") {
      (function () {
        window.sib = {
          equeue: [],
          client_key: "badvtf8rg93g3n0oejmli0ls"
        };
        window.sib.email_id = email;
        window.sendinblue = {};
        let actions = ["track", "identify", "trackLink", "page"];
        for (var i = 0; i < actions.length; i++) {
          (function (k) {
            window.sendinblue[k] = function () {
              var arg = Array.prototype.slice.call(arguments);
              (
                window.sib[k] ||
                function () {
                  var t = {};
                  t[k] = arg;
                  window.sib.equeue.push(t);
                }
              )(arg[0], arg[1], arg[2]);
            };
          })(actions[i]);
        }

        var n = document.createElement("script");
        var inlineScript = document.getElementsByTagName("script")[0];
        n.type = "text/javascript";
        n.id = "sendinblue-js";
        n.async = !0;
        n.src = "https://sibautomation.com/sa.js?key=" + window.sib.client_key;
        inlineScript.parentNode.insertBefore(n, inlineScript);
        window.sendinblue.page();
      })();
    } else {
      console.log("Do not show Project Cece's Sendinblue chat");
    }
  }
};

const mutations = {
  refreshTokenPromise(state, promise) {
    state.refreshTokenPromise = promise;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, tokens) {
    state.isAuthenticated = true;
    state.tokens.refresh = tokens.refresh;
    state.tokens.access = tokens.access;
    state.errors = {};
    JwtService.saveToken(tokens.access);
    JwtService.saveRefreshToken(tokens.refresh);
    ApiService.mount401Interceptor();
  },
  [UPDATE_AUTH](_state, { accessToken, refreshToken }) {
    JwtService.saveToken(accessToken);
    if (refreshToken) {
      JwtService.saveRefreshToken(refreshToken);
    }
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.errors = {};
    state.tokens = {};
    window.localStorage.removeItem("sib.email");
    JwtService.destroyTokens();
  },
  [CLEAR_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
