import { LabelService } from "@/services/label.service";

// action types
export const FETCH_LABELS = "fetchLabels";

// mutation types
export const SET_LABELS = "setLabels";

/**
 * @typedef {Object} State
 * @property {Label[]} labels
 */

/** @type {State} */
const state = {
  labels: null
};

const getters = {
  labelMap() {
    if (!state.labels) return {};
    return state.labels.reduce((map, label) => {
      map[label.abbreviation] = label;
      return map;
    }, {});
  },
  /**
   * Generates filter options based on label array
   * @returns {{ label: string, value: string, icon: string }[]}
   */
  labelFilterOptions() {
    if (!state.labels) return [];
    return state.labels.map(label => ({
      label: label.label_name,
      value: label.abbreviation,
      icon: LabelService.getLabelIcon(label.abbreviation)
    }));
  }
};

const actions = {
  async [FETCH_LABELS](context) {
    if (state.labels) return;
    try {
      const labels = await LabelService.getLabels();
      context.commit(SET_LABELS, labels);
    } catch (err) {
      console.error("Error fetching labels:", err);
    }
  }
};

const mutations = {
  /**
   * @param {State} state
   * @param {Label[]} labels
   */
  [SET_LABELS](state, labels) {
    state.labels = labels;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
