import ApiService from "./api.service";

/**
 * Retrieves materials
 * @returns {Promise<Material[]>} materials
 */
export async function getMaterials() {
  /** @type {{ data: { results: Material[] } }} */
  const {
    data: { results: materals }
  } = await ApiService.get("/ib/forpublishers/materials");
  return materals;
}
