import Vue from "vue";
import * as DealService from "@/services/deal.service";
import { SET_STORE_DATA } from "./user.module";
import i18n from "@/plugins/vue-i18n";

// actions
export const GET_DEALS = "getDeals";
export const CREATE_DEAL = "createDeal";
export const ACCEPT_DEAL = "acceptDeal";
export const DENY_DEAL = "denyDeal";
export const CREATE_DEAL_MESSAGE = "createDealMessage";
export const MARK_DEAL_MESSAGE_SEEN = "markDealMessageSeen";
export const UPDATE_DEAL = "updateDeal";
export const CLEAR = "clearDealModule";

// mutations
export const SET_DEALS = "setDeals";
export const ADD_DEAL = "addDeal";
export const REPLACE_DEAL = "replaceDeal";
export const REMOVE_DEAL = "removeDeal";
export const UPDATE_DEAL_MESSAGE = "updateDealMessage";
export const CLEAR_STATE = "clearDealState";


// errors
export const DEAL_LIMIT_REACHED_ERROR = "DEAL_LIMIT_REACHED";

const state = {
  /** @type {Deal[]} */
  deals: [],
  /** @type {{ label: import("vue-i18n").TranslateResult, value: string }[]} */
  filterOptions: [
    {
      label: i18n.t(`DEAL_STATUS.SHORT.${DealService.DEAL_STATUS_ACTIVE}`),
      value: DealService.DEAL_STATUS_ACTIVE
    },
    {
      label: i18n.t(`DEAL_STATUS.SHORT.${DealService.DEAL_STATUS_PENDING}`),
      value: DealService.DEAL_STATUS_PENDING
    },
    {
      label: i18n.t(`DEAL_STATUS.SHORT.${DealService.DEAL_STATUS_REJECTED}`),
      value: DealService.DEAL_STATUS_REJECTED
    },
    {
      label: i18n.t(`DEAL_STATUS.SHORT.${DealService.DEAL_STATUS_EXPIRED}`),
      value: DealService.DEAL_STATUS_EXPIRED
    }
  ],
  fullFilterOptions: [
    {
      label: "No status",
      value: ""
    },
    {
      label: "Pending: Request sent",
      value: DealService.DEAL_STATUS_INVITE_SENT
    },
    {
      label: "Pending: Request received",
      value: DealService.DEAL_STATUS_INVITE_RECEIVED
    },
    {
      label: "Active",
      value: DealService.DEAL_STATUS_ACTIVE
    },
    {
      label: "Not accepted",
      value: DealService.DEAL_STATUS_REJECTED
    },
    {
      label: "Expired",
      value: DealService.DEAL_STATUS_EXPIRED
    }
    
  ]
};

/** @type {import("vuex").GetterTree<typeof state>} */
const getters = {
  dealRequests(state, getters) {
    if (!state.deals) return [];
    const me = getters.isPublisherUser ? "store" : "publisher";
    return state.deals.filter(
      d => d.initiated_by === me && d.status === DealService.DEAL_STATUS_PENDING
    );
  },
  fullDealStatus(getters, deal) {
    const me = getters.isPublisherUser ? "store" : "publisher";
    if (deal.status === DealService.DEAL_STATUS_PENDING) {
      if (deal.initiated_by === me) {
        return DealService.DEAL_STATUS_INVITE_SENT
      } 
      return DealService.DEAL_STATUS_INVITE_RECEIVED
    } 
    return deal.status;
  }
};

/** @type {import("vuex").ActionTree<typeof state>} */
const actions = {
  async [GET_DEALS]({ commit, getters }) {
    try {
      /** @type {Deal[]} */
      let deals;
      if (getters.isWebshopUser) {
        deals = await DealService.store.getMyDeals();
      } else if (getters.isPublisherUser) {
        deals = await DealService.publisher.getMyDeals();
      } else {
        throw new Error(
          "Store: GET_DEALS: User is not store user or publisher user"
        );
      }
      commit(SET_DEALS, {
        deals
      });
    } catch (err) {
      console.error("Error fetching deals", err);
    }
  },
  /**
   * Creates a new deal and adds it to the cached list of deals
   * @param {{ commit: any, state: typeof state, getters: any }} context
   * @param {{ storeId: number, publisherId: number, message?: string }} params
   */
  async [CREATE_DEAL](
    { commit, getters, state },
    { storeId, publisherId, message }
  ) {
    try {
      let deal;
      if (getters.isWebshopUser) {
        deal = await DealService.store.createDeal({
          publisherId,
          message
        });
        // Store in user data if store is allowed to make more deals afterwards
        if (deal.allow_new_deal !== undefined) {
          commit(SET_STORE_DATA, {
            affiliate: { allow_new_deal: deal.allow_new_deal }
          });
        }
      } else if (getters.isPublisherUser) {
        deal = await DealService.publisher.createDeal({
          storeId,
          message
        });
      } else {
        throw new Error(
          "CREATE_DEAL error: given storeId or publisherId are not from current active user"
        );
      }

      // Remove previous deal if there already was one (an expired or rejected deal)
      const existingDeal = state.deals.find(
        d => d.store === storeId && d.publisher === publisherId
      );
      if (existingDeal) {
        if (
          existingDeal.status === DealService.DEAL_STATUS_PENDING ||
          existingDeal.status === DealService.DEAL_STATUS_ACTIVE
        ) {
          console.error(
            `Creating new deal while there was already an existing deal with status ${existingDeal.status}`
          );
        }
        commit(REMOVE_DEAL, existingDeal);
        console.log("after remove deals", JSON.stringify(state.deals));
      }

      // Add the new deal
      commit(ADD_DEAL, deal);

      return deal;
    } catch (err) {
      const error = err?.response?.data?.[0];
      if (error === "Deal limit reached") {
        // Deal limit is reached, store in profile that no more deals can be made
        if (getters.isWebshopUser) {
          commit(SET_STORE_DATA, {
            affiliate: { allow_new_deal: false }
          });
        }
        throw new Error(DEAL_LIMIT_REACHED_ERROR);
      } else {
        throw err;
      }
    }
  },
  async [CREATE_DEAL_MESSAGE]({ getters }, { dealId, message }) {
    if (getters.isPublisherUser) {
      return DealService.publisher.createMessage({ dealId, message });
    } else {
      return DealService.store.createMessage({ dealId, message });
    }
  },
  async [UPDATE_DEAL]({ getters }, { dealId, updatedFields }) {
    // let deal;
    if (getters.isWebshopUser) {
      return DealService.store.updateDeal(dealId, updatedFields);
    }
    // commit(REPLACE_DEAL, deal);
  },
  async [MARK_DEAL_MESSAGE_SEEN]({ getters, commit }, { dealId, messageId }) {
    let message;
    if (getters.isPublisherUser) {
      message = await DealService.publisher.markMessageAsSeen({ messageId });
    } else {
      message = await DealService.store.markMessageAsSeen({ messageId });
    }
    commit(UPDATE_DEAL_MESSAGE, { dealId, message });
  },
  /**
   * Creates a new deal and adds it to the cached list of deals
   * @param {{ commit: import("vuex").Commit, state: typeof state, getters: any }} context
   * @param {{ dealId: number }} params
   */
  async [ACCEPT_DEAL]({ commit, getters }, { dealId }) {
    /** @type {Deal} */
    let deal;
    if (getters.isPublisherUser) {
      deal = await DealService.publisher.respond(dealId, { accept: true });
    } else {
      deal = await DealService.store.respond(dealId, { accept: true });
      // Store in user data if store is allowed to make more deals afterwards
      if (deal.allow_new_deal !== undefined) {
        commit(SET_STORE_DATA, {
          affiliate: { allow_new_deal: deal.allow_new_deal }
        });
      }
    }
    commit(REPLACE_DEAL, deal);
    return deal;
  },
  /**
   * Creates a new deal and adds it to the cached list of deals
   * @param {{ commit: import("vuex").Commit, state: typeof state, getters: any }} context
   * @param {{ dealId: number, reason?: string }} params
   */
  async [DENY_DEAL]({ commit, getters }, { dealId, reason }) {
    /** @type {Deal} */
    let deal;
    if (getters.isPublisherUser) {
      deal = await DealService.publisher.respond(dealId, {
        accept: false,
        reason
      });
    } else {
      deal = await DealService.store.respond(dealId, { accept: false, reason });
      // Store in user data if store is allowed to make more deals afterwards
      if (deal.allow_new_deal !== undefined) {
        commit(SET_STORE_DATA, {
          affiliate: { allow_new_deal: deal.allow_new_deal }
        });
      }
    }
    commit(REPLACE_DEAL, deal);
    return deal;
  },
  [CLEAR](context) {
    context.commit(CLEAR_STATE);
  }
};

/** @type {import("vuex").MutationTree<typeof state>} */
const mutations = {
  [SET_DEALS](state, { deals }) {
    state.deals = deals;
  },
  [ADD_DEAL](state, deal) {
    state.deals = [...state.deals, deal];
  },
  /**
   * Replace a deal in the state deal array
   * @param {typeof state} state
   * @param {Deal} deal
   */
  [REPLACE_DEAL](state, deal) {
    const index = state.deals.findIndex(d => d.id === deal.id);
    if (index === -1) {
      throw new Error(`REPLACE_DEAL: deal with id ${deal.id} not found`);
    }
    Vue.set(state.deals, index, deal);
  },
  /**
   * Replaces or adds a deal in the state deal array
   * @param {typeof state} state
   * @param {Deal} deal
   */
  [REMOVE_DEAL](state, deal) {
    const index = state.deals.findIndex(d => d.id === deal.id);
    if (index === -1) {
      throw new Error(`REMOVE_DEAL: deal with id ${deal.id} not found`);
    }
    const deals = [...state.deals];
    deals.splice(index, 1);
    state.deals = deals;
  },
  [UPDATE_DEAL_MESSAGE](state, { dealId, message }) {
    const index = state.deals.findIndex(d => d.id === dealId);
    if (index === -1)
      throw new Error(
        `Error updating deal message, can't find deal with id ${dealId}`
      );
    const messageIndex = state.deals[index].messages.findIndex(
      m => m.id === message.id
    );
    if (messageIndex === -1)
      throw new Error(
        `Error updating deal message, can't find message with id ${message.id}`
      );
    Vue.set(state.deals[index].messages, messageIndex, message);
  },
  [CLEAR_STATE](state) {
    state.deals = [];
  }
  // [UPDATE_DEAL](state, deal) {
  //   const index = state.deals.findIndex(d => d.id === dealId);
  //   if (index === -1)
  //     throw new Error(
  //       `Error updating deal percentage, can't find deal with id ${dealId}`
  //     );
  //   Vue.set(state.deals[index], "percentage", percentage);
  // }
};

export default {
  state,
  getters,
  actions,
  mutations
};
