export default {
  api: {
    baseURL: "https://staging.projectcece.com/api"
  },
  typesense: {
    host: "tori51mnjpx6d40sp-1.a1.typesense.net",
    port: "443",
    protocol: "https",
    apiKey: "A9Wq5TclHLE9ysoXcywdJf5x9meeEbpa", // store-dashboard staging search key
    storeCollection: "staging_Store",
    publisherCollection: "staging_Publisher",
    productCollection: "staging_Product"
  },
  // Publishers get 5% commission of final product price when linking
  // to projectcece.*
  projectCeceCommission: 5,
  impactbytesUrls: {
    app: "https://stagingapp.impactbytes.io",
    pricing: "https://www.impactbytes.io/webshop-pricing",
    landing: "https://www.impactbytes.io",
    impactAnalysis: "https://projectcece.typeform.com/to/ua4zDA",
    calendyMeetingNoor: "https://calendly.com/impactbytes/15-min-call"
  },
  email:
    process.env.VUE_APP_ID === "impactbytes"
      ? "info@impactbytes.io"
      : "info@projectcece.nl",
  matomo: {
    enabled: true,
    host: "https://m.cece.eco/",
    siteId: 11,
    trackerFileName: "matomo"
  },
  menu: {
    contact: {
      enabled: false
    },
    faq: {
      enabled: true
    }
  },

  // Enable (new) features:
  featureFlags: {
    showAnalyticsPublisherFilter: true,
    enableNewImpactAnalysis: true,
    showUpgradeBanner: true,
    showBlackFridayBanner: false,
    showPublisherClicksPerUrlAnalytics: true
  },

  // Publisher settings
  publisher: {
    // Max lenght of short description field of publisher profile
    shortDescriptionMaxLength: 200
  },

  // Jotform ids, used to load the right form with a webhook to
  // the right server/environment. See production/staging config
  jotform: {
    brandInfoFormId: null,
    multiBrandInfoFormId: null,
    materialFormId: null,
    supplierFormId: null
  },

  // Terms and conditions versions with date since valid
  webshopTerms: {
    latest: "03-10-2023",
    v16022023: "16-02-2023",
    v16012020: "16-01-2020"
  },

  // Impact Analysis categories
  impactCategories: [
    "Luggage & Bags",
    "Beauty",
    "Clothing & Accessories",
    "Jewelry",
    "Shoes"
  ]
};

