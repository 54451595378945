<template>
  <component :is="getLayout(layout)">
    <slot />
  </component>
</template>

<script>
const defaultLayout = "Empty";
export default {
  data() {
    return {
      cache: {}
    };
  },
  computed: {
    layout() {
      // Find first matching router item with a layout defined, from lowest level to high level
      const item = [...this.$route.matched]
        .reverse()
        .find(item => item.meta && item.meta.layout);

      // Use layout of found router item, or use the default layout
      const layout =
        item && item.meta && item.meta.layout
          ? item.meta.layout
          : defaultLayout;

      return layout;
    }
  },
  methods: {
    getLayout(layout) {
      // Cache dynamic imports so that this component doesn't think that the
      // same component is actually different
      if (this.cache[layout]) return this.cache[layout];
      this.cache[layout] = () => import(`@/layout/${layout}.vue`);
      return this.cache[layout];
    }
  }
};
</script>
