import { fixUrl } from "@/lib/url";
import kebabCase from "lodash/kebabCase";

import ApiService from "./api.service";
import { DEAL_STATUS_ACTIVE } from "./deal.service";

export const TRANSACTION_STATUS_PENDING = 0;
export const TRANSACTION_STATUS_CONFIRMED = 1;
export const TRANSACTION_STATUS_CANCELLED = 2;
export const TRANSACTION_STATUS_PAID = 3;

export const TRANSACTION_STATUS = {
  [TRANSACTION_STATUS_PENDING]: "pending",
  [TRANSACTION_STATUS_CONFIRMED]: "confirmed",
  [TRANSACTION_STATUS_CANCELLED]: "cancelled",
  [TRANSACTION_STATUS_PAID]: "paid"
};

export const PAYMENT_STATUS_PENDING = 1;
export const PAYMENT_STATUS_PAID = 2;

export const PAYMENT_STATUS = {
  [PAYMENT_STATUS_PENDING]: "pending",
  [PAYMENT_STATUS_PAID]: "paid"
};

export const PAYMENT_VARIANT = {
  [PAYMENT_STATUS_PENDING]: "info",
  [PAYMENT_STATUS_PAID]: "success"
};

/**
 * Gets user's publisher data
 * @returns {Promise<Publisher>} User's publisher data
 */
export async function getMyPublisher() {
  /** @type {{ data: Publisher }} */
  const { data: publisher } = await ApiService.get(
    "/ib/forpublishers/publisher/me"
  );
  return publisher;
}

/**
 * Create a publisher entity for the current user
 * @param {{
 *   name: string,
 *   logo?: File,
 *   link: string,
 *   short_description?: string,
 *   email: string,
 *   notifications?: boolean
 * }} params
 * @returns
 */
export function createMyPublisher({
  name,
  logo,
  link,
  short_description,
  email,
  notifications,
  store_invite = null, 
  referrer = null
}) {
  let data = new FormData();
  data.append("name", name);
  data.append("email", email);
  data.append("link", fixUrl(link));
  if (logo) {
    data.append("logo", logo, logo.name);
  }
  if (short_description) {
    data.append("short_description", short_description);
  }
  if (notifications !== undefined) {
    data.append("notifications", notifications);
  }
  if (store_invite) {
    data.append("store_invite", store_invite);
  }
  if (referrer) {
    data.append("referrer", referrer);
  }
  return ApiService.post("/ib/forpublishers/publisher/", data);
}

/**
 * Updates user's publisher
 *
 * @typedef {Object} updateMyPublishersInput
 * @prop {string=} name
 * @prop {File|string=} logo
 * @prop {string=} link
 * @prop {string=} short_description
 * @prop {string=} long_description
 * @prop {number=} percentage
 * @prop {boolean=} notifications
 * @prop {string[]=} media
 * @prop {string[]=} topics
 * @prop {string[]=} target_audience
 * @prop {number=} total_min_reach
 * @prop {string=} IBAN
 * @prop {string=} invoice_name
 * @prop {string=} tax_number
 * @prop {string=} invoice_addressline1
 * @prop {string=} invoice_addressline2
 * @prop {{postback_url: string }=} integrations
 * @prop {number=} payment_threshold
 *
 * @param {updateMyPublishersInput} data
 * @returns {Promise<Publisher>} publisher
 */
export async function updateMyPublisher({
  name,
  logo,
  link,
  short_description,
  long_description,
  percentage,
  notifications,
  media,
  topics,
  target_audience,
  total_min_reach,
  IBAN,
  invoice_name,
  tax_number,
  invoice_addressline1,
  invoice_addressline2,
  integrations,
  payment_threshold
}) {
  // Handle logo upload if logo is defined
  // using form-urlencoded data
  // If the logo is a string ignore it as thats un unchanged value
  // so no need to send it (again) to the server
  if (logo !== undefined && (!logo || typeof logo !== "string")) {
    let data = new FormData();
    if (logo && typeof logo !== "string") {
      data.append("logo", logo, logo.name);
    } else if (!logo) {
      data.append("logo", "");
    }
    await ApiService.patch("/ib/forpublishers/publisher/me/", data);
  }

  // Handle other fields using JSON
  const add = {};
  if (link !== undefined) {
    add.link = link ? fixUrl(link) : "";
  }
  const { data: publisher } = await ApiService.patch(
    "/ib/forpublishers/publisher/me/",
    {
      name,
      short_description,
      long_description,
      percentage,
      notifications,
      media,
      topics,
      target_audience,
      total_min_reach,
      IBAN,
      invoice_name,
      tax_number,
      invoice_addressline1,
      invoice_addressline2,
      integrations,
      payment_threshold,
      ...add
    }
  );
  return publisher;
}

/**
 * Get information for publisher's transactions.
 * @param {{ page: Number, page_size: Number }} params GET parameters
 * @returns {Promise<{ results: Transaction[] }>}
 */
export async function getTransactions(
  { page, page_size } = { page: 1, page_size: 48 }
) {
  /** @type {{ data: { results: Transaction[] } }} */
  const { data: transactions } = await ApiService.get(
    "ib/forpublishers/transaction",
    "",
    {
      authorization: true,
      params: {
        page,
        page_size
      }
    }
  );
  return transactions;
}

/**
 * Get information for publisher's payments
 * @param {{ page: Number, page_size: Number }} params Get parameters
 * @returns {Promise<{ results: Payout[] }>}
 */
export async function getPayouts({ page, page_size }) {
  /** @type {{ data: { results: Payout[] } }} */
  const { data } = await ApiService.get("ib/forpublishers/payout", "", {
    authorization: true,
    params: { page, page_size }
  });
  return data;
}

/**
 * Fetches affiliate link
 * @param {{ url?: string, store_id?: number|string, product_id?: string }} params
 * @returns {Promise<string>}
 */
export async function getAffiliateLink({ url, store_id, product_id }) {
  /** @type {{ data: { link: string } }} */
  const {
    data: { link }
  } = await ApiService.get("ib/forpublishers/publisher/me/get-link", "", {
    params: { url, store_id, product_id },
    authorization: true
  });
  return link;
}

/**
 * Gets publisher quick stats
 * @param {{ month?: number, year?: number }} params
 * @returns {Promise<PublisherQuickStats>}
 */
export async function getQuickStats({ month, year } = {}) {
  /** @type {{ data: PublisherQuickStats }} */
  const { data: stats } = await ApiService.get(
    "ib/forpublishers/publisher/me/quick-stats",
    "",
    {
      params: { month, year },
      authorization: true
    }
  );
  return stats;
}

/**
 * Gets publisher payout  stats
 */
export async function getPayoutStats() {
  const { data: stats } = await ApiService.get("ib/forpublishers/publisher/me/payout-stats");
  return stats;
}

/**
 * Gets settings field options & suggestions
 * @returns {Promise<{ total_min_reach: number[], media: string[], topics: string[], target_audience: string[] }>}
 */
export async function getSettingsOptions() {
  /** @type {{ data: { options: { total_min_reach: number[] }, suggestions: { media: string[], topics: string[], target_audience: string[] }}}} */
  const {
    data: {
      options: { total_min_reach },
      suggestions: { media, topics, target_audience }
    }
  } = await ApiService.get("ib/forpublishers/publisher/field-options", "");
  return { total_min_reach, media, topics, target_audience };
}

/**
 * Returns analytics data for number of sales and sales commission for a certain
 * time range
 * @param {{ start: Date, range: Number, unit: "day"|"week"|"month", store?: Number }} params
 * @returns {Promise<{ sales: Number[], commission: Number[], xAxis: string[]}>}
 */
export async function getSalesAnalytics({ start, range, unit, store }) {
  const {
    data: { sales, commission, xAxis }
  } = await ApiService.get("ib/forpublishers/plots/sales", "", {
    params: {
      // dd-mm-yyyy
      start: `${start.getDate()}-${
        start.getMonth() + 1
      }-${start.getFullYear()}`,
      range,
      unit,
      store
    },
    authorization: true
  });
  return { sales, commission, xAxis };
}

/**
 * Returns analytics data for number of clicks
 * @param {{ start: Date, range: Number, unit: "day"|"week"|"month", store?: Number }} params
 * @returns {Promise<{ clicks: Number[], xAxis: string[]}>}
 */
export async function getClickAnalytics({ start, range, unit, store }) {
  const {
    data: { clicks, xAxis }
  } = await ApiService.get("ib/forpublishers/plots/clicks", "", {
    params: {
      // dd-mm-yyyy
      start: `${start.getDate()}-${
        start.getMonth() + 1
      }-${start.getFullYear()}`,
      range,
      unit,
      store
    },
    authorization: true
  });
  return { clicks, xAxis };
}

/**
 * Returns analytics data for number of clicks per url
 * @param {{ start: Date, range: Number, unit: "day"|"week"|"month", store?: Number }} params
 * @returns {Promise<{ data: { url: string, clicks: Number }[] }>}
 */
export async function getClickAnalyticsList({ start, range, unit, store }) {
  const {
    data: { data }
  } = await ApiService.get("ib/forpublishers/list/clicks", "", {
    params: {
      // dd-mm-yyyy
      start: `${start.getDate()}-${
        start.getMonth() + 1
      }-${start.getFullYear()}`,
      range,
      unit,
      store
    },
    authorization: true
  });
  return data;
}

/**
 * Gets advertisers with which the current publisher has a (pending) deal
 * @returns {Promise<Store[]>} advertisers
 */
export async function getMyStores() {
  const {
    data: { results }
  } = await ApiService.get("ib/forpublishers/mystores", "");
  return results;
}

/**
 * Get analytics for the Project Cece merchant
 * @returns {Promise<{ clicks_this_month: Number, sales_this_month: Number} >}
 */
export async function getCeceData() {
  const {
    data: { clicks_this_month, sales_this_month }
  } = await ApiService.get("ib/forpublishers/publisher/me/get-cece-data");
  return { clicks_this_month, sales_this_month };
}

/**
 * Gets slugs of stores where the publisher has an active deal with
 * @returns {Promise<string[]>}
 */
export async function getMyActiveStoresName() {
  /** @type {{ data: { results: Store[] }}} */
  const {
    data: { results }
  } = await ApiService.get("ib/forpublishers/mystores", "");
  return results
    .filter(r => r.deals[0].status === DEAL_STATUS_ACTIVE)
    .map(r => r.store_name);
}

/**
 * Get a single store
 * @param {number} id
 * @returns {Promise<Store>}
 */
export async function getStore(id) {
  /** @type {{ data: Store }} */
  const { data: store } = await ApiService.get(`ib/forpublishers/store/${id}`);
  return store;
}

/**
 * Returns the url to the store on the publisher side of the platform
 * @param {string|number} id
 * @param {string} store_name
 * @returns
 */
export function getStoreUrl(id, store_name) {
  if (!id) throw new Error("id is a required parameter of getStoreUrl");

  if (id === -1) {
    return "/p/affiliate/advertisers/project-cece/";
  }

  if (!store_name) {
    throw new Error("store_name is a required parameter of getStoreUrl");
  }

  return `/p/affiliate/advertisers/${id}-${kebabCase(store_name)}/`;
}

/**
 * Get a single store
 * @param {number} id
 * @returns {Promise<{ data: { currency_code: string, currency: Number, language: string, url: string }[] }>}
 */
export async function getMyStoreDataFeeds(id) {
  const { data: feeds } = await ApiService.get(`ib/forpublishers/mystores/${id}/product-feeds`);
  return feeds;
}