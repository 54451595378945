// USA
export const locale = {
  LANG_TEST: "De huidige taal is: Nederlands",
  TRANSLATOR: {
    SELECT: "Kies een taal"
  },
  MENU: {
    NEW: "nieuw",
    ACTIONS: "Acties",
    CREATE_POST: "Maak nieuwe post",
    PAGES: "Paginas",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard"
  },
  PAGE_TITLES: {
    PARTNERS: "Partners",
    SETTINGS: "Instellingen",
    USER_SETTINGS: "Gebruiker Instellingen",
    SALES: "Sales Overzicht",
    INVOICES: "Facturen Overzicht",
    INVOICE_WIZARD: "Betalen",
    PAYMENT_SUCCESS: "Bedankt voor het betalen!",
    LOGIN: "Login",
    SIGNUP: "Registreren",
    PW_RESET: "Wachtwoord vergeten?",
    PW_CHANGE: "Wachtwoord veranderen",
    E404: "Pagina niet gevonden",
    FFGC_TRANSACTIONS: "Transacties",
    FFGC_PAYMENTS: "Betalingen"
  },
  QUICK_USER: {
    ACCOUNT_SETTINGS: "Account Instellingen"
  },
  SIGNUP: {
    TITLE: "Registreren",
    ALREADY_AN_ACCOUNT: "Heb je al een account?",
    LOG_IN: "Log hier in",
    GENERAL_ERROR:
      "Er is iets fout gegaan, probeer het in enkele momenten opnieuw.",
    FORM: {
      USERTYPE: {
        LABEL: "Ik ben een...",
        PLACEHOLDER: "Maak een keuze",
        PUBLISHER: "Publisher/Media",
        WEBSHOP: "Webshop/Merk",
        TOOLTIP: "Register as a webshop/brand if you sell sustainable products online. Register as Publisher/Media if you want to browse and promote sustainable products and brands."
      },
      NAME: {
        LABEL: "Naam",
        REQUIRED: "Voer je naam in"
      },
      EMAIL: {
        LABEL: "E-mail",
        REQUIRED: "Voer een e-mail adres in",
        VALID: "Gebruik een geldig e-mail adres"
      },
      PASSWORD: {
        LABEL: "Wachtwoord",
        REPEAT_LABEL: "Herhaal wachtwoord",
        REQUIRED: "Voer een wachtwoord in",
        MINLENGTH: "Het wachtwoord moet langer dan 5 tekens zijn",
        NOT_EQUAL: "De twee wachtwoorden zijn niet gelijk"
      },
      BUTTON: "Registreren"
    }
  },
  EMAIL_CONFIRMATION_SETTINGS: {
    TITLE: "E-mail confirmatie",
    TEXT:
      "Je e-mail adres is not niet bevestigt. Gebruik de knop in de bevestigings e-mail die we naar {email} verstuurd hebben om je e-mail adres te bevestigen, of gebruik de knop hieronder om de bevestigings e-mail nogmaals te versturen.",
    BUTTON: "Verstuur bevestigings e-mail",
    ERROR:
      "Er is helaas iets fout gegaan bij het versturen van een bevestigings e-mail. Probeer het nogmaals in enkele minuten, mocht het probleem blijven bestaan neem dan context op met {email}",
    SUCCESS: "Er is een bevestigings e-mail verstuurd naar {email}!"
  }
};
