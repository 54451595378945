import ApiService from "./api.service";

/**
 * Gets all possible shipping locations of stores
 */
async function getCountrySites() {
  const {
    data: { results: shipping }
  } = await ApiService.get("dashboard/countrysites");
  return shipping;
}

function getCountrySiteIcon(icon) {
  return `https://projectcece.com${icon}`;
}

export const CountrySiteService = { getCountrySites, getCountrySiteIcon };
