import i18n from "@/plugins/vue-i18n";

export const routes = [
  {
    name: "invite",
    path: "/invite",
    redirect: "/signup/",
    meta: {
      public: true
    }
  },
  {
    name: "signup",
    path: "/signup/",
    component: () =>
      process.env.VUE_APP_ID === "impactbytes"
        ? import(
            /* webpackChunkName: "signup" */
            "@/pages/auth/Signup.vue"
          )
        : import(
            /* webpackChunkName: "error-1" */
            "@/pages/error/Error-1.vue"
          ),
    meta: {
      layout:
        process.env.VUE_APP_ID === "impactbytes" ? "AuthPublisher" : "Auth",
      public: true, // Allow access to even when not logged in
      unauthenticatedOnly: true, // Not accessible when authenticated
      title: i18n.t("PAGE_TITLES.SIGNUP")
    }
  },
  {
    name: "login",
    path: "/login/",
    component: () =>
      process.env.VUE_APP_ID === "impactbytes"
        ? import(
            /* webpackChunkName: "login-impactbytes" */
            "@/pages/auth/LoginImpactbytes"
          )
        : import(
            /* webpackChunkName: "login-storedashboard" */
            "@/pages/auth/LoginStoreDashboard"
          ),
    meta: {
      layout:
        process.env.VUE_APP_ID === "impactbytes" ? "AuthPublisher" : "Auth",
      public: true, // Allow access to even when not logged in
      unauthenticatedOnly: true, // Not accessible when authenticated
      title: i18n.t("PAGE_TITLES.LOGIN")
    }
  },
  {
    name: "password-reset",
    path: "/password-reset/",
    component: () =>
      import(
        /* webpackChunkName: "password-reset" */
        "@/pages/auth/PasswordReset"
      ),
    meta: {
      public: true, // Allow access to even when not logged in
      unauthenticatedOnly: true, // Not accessible when authenticated
      title: i18n.t("PAGE_TITLES.PW_RESET")
    }
  },
  {
    name: "password-reset-change",
    path: "/password-reset/change/:uidb64/:token",
    component: () =>
      import(
        /* webpackChunkName: "password-reset-form" */
        "@/pages/auth/PasswordResetForm"
      ),
    meta: {
      public: true, // Allow access to even when not logged in
      unauthenticatedOnly: true, // Not accessible when authenticated
      title: i18n.t("PAGE_TITLES.PW_RESET")
    }
  }
];
