import Vue from "vue";
import Router from "vue-router";
import i18n from "@/plugins/vue-i18n";

import { routes as auth } from "@/pages/auth/auth.router.js";
import { routes as webshop } from "@/pages/webshop/webshop.router.js";
import { routes as publisher } from "@/pages/publisher/publisher.router.js";
import store from "@/store";

Vue.use(Router);

// Get correct path base depending on user type
const getInitialUrl = () => {
  if (!store.getters.isAuthenticated) {
    return "/login/";
  }
  if (store.getters.isPublisherUser) {
    return "/p/dashboard/";
  }
  return "/w/dashboard/";
};

export default new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      // Give the page 200ms extra time to load the page before
      // trying to jump to a specific element
      return new Promise(resolve => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
            offset: { x: 0, y: 65 + 20 }
          });
        }, 200);
      });
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/",
      redirect: getInitialUrl()
    },
    // Old webshop routes to new webhop routes on /w/*
    {
      path: "/dashboard/*",
      redirect: "/w/dashboard/*"
    },
    {
      path: "/settings/*",
      redirect: "/w/settings/*"
    },
    {
      path: "/finance/*",
      redirect: "/w/finance/*"
    },
    {
      path: "/giftcard/*",
      redirect: "/w/giftcard/*"
    },
    // Webshop routes
    {
      path: "/w/terms-and-conditions/",
      name: "store-terms-and-conditions",
      component: () =>
        import(
          /* webpackChunkName: "store-terms" */
          "@/pages/webshop/terms/Terms.vue"
        ),
      meta: {
        public: true,
        layout: "Empty",
        title: i18n.t("PAGE_TITLES.STORE_TERMS_AND_CONDITIONS")
      }
    },
    {
      path: "/w/terms-and-conditions/:versionId/",
      name: "store-terms-and-conditions",
      component: () =>
        import(
          /* webpackChunkName: "store-terms" */
          "@/pages/webshop/terms/Terms.vue"
        ),
      meta: {
        public: true,
        layout: "Empty",
        title: i18n.t("PAGE_TITLES.STORE_TERMS_AND_CONDITIONS")
      }
    },
    {
      path: "/w",
      redirect: "/w/dashboard/",
      component: {
        render: c => c("router-view")
      },
      children: webshop,
      meta: {
        layout: "Dashboard",
        webshopUserOnly: true
      }
    },
    // Publisher routes
    {
      path: "/p/terms-and-conditions/",
      name: "pubilsher-terms-and-conditions",
      component: () =>
        import(
          /* webpackChunkName: "publisher-terms" */
          "@/pages/publisher/Terms.vue"
        ),
      meta: {
        public: true,
        layout: "Empty",
        title: i18n.t("PAGE_TITLES.PUBLISHER_TERMS_AND_CONDITIONS")
      }
    },
    {
      path: "/p",
      redirect: "/p/dashboard/",
      component: {
        render: c => c("router-view")
      },
      meta: {
        layout: "Dashboard",
        publisherUserOnly: true
      },
      children: publisher
    },
    // Authentication log in/sign up routes
    {
      path: "/",
      component: {
        render: c => c("router-view")
      },
      meta: {
        layout:
          process.env.VUE_APP_ID === "impactbytes"
            ? "AuthPublisherCard"
            : "Auth"
      },
      children: auth
    },
    {
      path: "/contact/",
      component: () => import("@/pages/contact/Contact.vue"),
      meta: {
        layout: "Dashboard",
        title: i18n.t("CONTACT.PAGE_TITLE")
      }
    },
    // Docs routes
    {
      path: "/docs/afpro/",
      component: {
        render: c => c("router-view")
      },
      meta: {
        layout: "AfproDoc"
      },
      children: [
        {
          name: "wix-docs",
          path: "wix/",
          component: () =>
            import(
              /* webpackChunkName: "docs-wix" */
              "@/pages/docs/Wix"
            ),
          meta: {
            public: true,
            title: "Wix AfPro installation"
          }
        },
        {
          name: "shopify-docs",
          path: "shopify/",
          component: () =>
            import(
              /* webpackChunkName: "docs-shopify" */
              "@/pages/docs/Shopify"
            ),
          meta: {
            public: true,
            title: "Shopify AfPro installation"
          }
        },
        {
          name: "squarespace-docs",
          path: "squarespace/",
          component: () =>
            import(
              /* webpackChunkName: "docs-squarespace" */
              "@/pages/docs/Squarespace"
            ),
          meta: {
            public: true,
            title: "Squarespace AfPro installation"
          }
        },
        {
          name: "wordpress-docs",
          path: "wordpress/",
          component: () =>
            import(
              /* webpackChunkName: "docs-wordpress" */
              "@/pages/docs/Wordpress"
            ),
          meta: {
            public: true,
            title: "Wordpress AfPro installation"
          }
        }
      ]
    },
    {
      path: "/docs/fairpro/",
      component: {
        render: c => c("router-view")
      },
      meta: {
        layout: "FairproDoc"
      },
      children: [
        {
          name: "fairpro-wordpress-docs",
          path: "wordpress/",
          component: () =>
            import(
              /* webpackChunkName: "fairpro-wordpress" */
              "@/pages/docs/fairpro/Wordpress"
            ),
          meta: {
            public: true,
            title: "Wordpress FairPro installation"
          }
        },
        {
          name: "fairpro-shopify-docs",
          path: "shopify/",
          component: () =>
            import(
              /* webpackChunkName: "fairpro-shopify" */
              "@/pages/docs/fairpro/Shopify"
            ),
          meta: {
            public: true,
            title: "Shopify FairPro installation"
          }
        },
        {
          name: "fairpro-shopify-docs-312",
          path: "shopify/3.1.2/",
          component: () =>
            import(
              /* webpackChunkName: "fairpro-shopify" */
              "@/pages/docs/fairpro/shopify/Shopify-3.1.2"
            ),
          meta: {
            public: true,
            title: "Shopify FairPro installation"
          }
        },
        {
          name: "fairpro-shopify-docs-313",
          path: "shopify/3.1.3/",
          component: () =>
            import(
              /* webpackChunkName: "fairpro-shopify" */
              "@/pages/docs/fairpro/shopify/Shopify-3.1.3"
            ),
          meta: {
            public: true,
            title: "Shopify FairPro installation"
          }
        },
        {
          name: "fairpro-squarespace-docs",
          path: "squarespace/",
          component: () =>
            import(
              /* webpackChunkName: "fairpro-squarespace" */
              "@/pages/docs/fairpro/Squarespace"
            ),
          meta: {
            public: true,
            title: "Squarespace FairPro installation"
          }
        },
        {
          name: "fairpro-generic-docs",
          path: "generic/",
          component: () =>
            import(
              /* webpackChunkName: "fairpro-generic" */
              "@/pages/docs/fairpro/Generic"
            ),
          meta: {
            public: true,
            title: "Squarespace FairPro installation"
          }
        },
        {
          name: "fairpro-lightspeed-docs",
          path: "lightspeed/",
          component: () =>
            import(
              /* webpackChunkName: "fairpro-lightspeed" */
              "@/pages/docs/fairpro/Lightspeed"
            ),
          meta: {
            public: true,
            title: "Lightspeed FairPro installation"
          }
        },
        {
          name: "fairpro-wix-docs",
          path: "wix/",
          component: () =>
            import(
              /* webpackChunkName: "fairpro-wix" */
              "@/pages/docs/fairpro/Wix"
            ),
          meta: {
            public: true,
            title: "Wix FairPro installation"
          }
        },
        {
          name: "fairpro-prestashop-docs",
          path: "prestashop/",
          component: () =>
            import(
              /* webpackChunkName: "fairpro-prestashop" */
              "@/pages/docs/fairpro/Prestashop"
            ),
          meta: {
            public: true,
            title: "Prestashop FairPro installation"
          }
        },
        {
          name: "fairpro-ccvshop-docs",
          path: "ccvshop/",
          component: () =>
            import(
              /* webpackChunkName: "fairpro-ccvshop" */
              "@/pages/docs/fairpro/CCVShop"
            ),
          meta: {
            public: true,
            title: "CCV Shop FairPro installation"
          }
        }
      ]
    },
    {
      path: "/fairfilliate/",
      component: {
        render: c => c("router-view")
      },
      meta: {
        layout: "FairfilliateDoc"
      },
      children: [
        {
          name: "terms-conditions",
          path: "terms-conditions/",
          component: () =>
            import(
              /* webpackChunkName: "fairfilliate-terms" */
              "@/pages/fairfilliate/TermsConditions"
            ),
          meta: {
            public: true,
            title: "Fairfilliate network terms"
          }
        },
        {
          name: "publishers",
          path: "publishers/",
          component: () =>
            import(
              /* webpackChunkName: "fairfilliate-publishers" */
              "@/pages/fairfilliate/Publishers"
            ),
          meta: {
            public: true,
            title: "Fairfilliate Publishers"
          }
        },
        {
          name: "fairpro",
          path: "fairpro/",
          component: () =>
            import(
              /* webpackChunkName: "fairfilliate-fairpro" */
              "@/pages/fairfilliate/Fairpro"
            ),
          meta: {
            public: true,
            title: "Fairfilliate Fairpro"
          }
        }
      ]
    },
    {
      path: "*",
      component: () =>
        import(
          /* webpackChunkName: "error-1" */
          "@/pages/error/Error-1.vue"
        ),
      meta: {
        layout: "Empty",
        title: i18n.t("PAGE_TITLES.E404")
      }
    }
  ]
});
