import { CountrySiteService } from "@/services/countrysite.service";

// action types
export const FETCH_COUNTRY_SITES = "fetchCountrySites";

// mutation types
export const SET_COUNTRY_SITES = "setCountrySites";

const state = {
  /** @type {CountrySite[]} */
  countrySites: null
};

const getters = {
  countrySiteMap() {
    if (!state.countrySites) return {};
    return state.countrySites.reduce((map, countrySite) => {
      map[countrySite.country_code] = countrySite;
      return map;
    }, {});
  },
  countrySiteFilterOptions() {
    if (!state.countrySites) return [];
    return state.countrySites.map(countrySite => ({
      label: countrySite.name,
      value: countrySite.country_code,
      icon: CountrySiteService.getCountrySiteIcon(countrySite.icon)
    }));
  }
};

const actions = {
  async [FETCH_COUNTRY_SITES](context) {
    if (state.countrySites) return;
    const countrySites = await CountrySiteService.getCountrySites();
    context.commit(SET_COUNTRY_SITES, countrySites);
  }
};

const mutations = {
  [SET_COUNTRY_SITES](state, countrySites) {
    state.countrySites = countrySites;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
