const state = {
  tasks: [
    {
      name: "name",
      action: "Tell us your name"
    },
    {
      name: "logo",
      action: "Upload a logo",
      link: "/p/settings/publisher/#logo-group"
    },
    {
      name: "short_description",
      action: "Enter a short description",
      link: "/p/settings/publisher/#short_description-group"
    },
    {
      name: "long_description",
      action: "Enter a profile page description",
      link: "/p/settings/publisher/#long_description-group"
    },
    {
      name: "platform_audience",
      action: "Provide info about your platform and audience",
      link: "/p/settings/publisher/#platform-and-audience"
    },
    {
      name: "reach",
      action: "Define your total visitors reach",
      link: "/p/settings/publisher/#total_min_reach-group"
    }
  ]
};
const getters = {
  tasks(state, _getters, rootState) {
    const done = {
      name: true,
      logo: !!rootState.user.publisher.logo,
      short_description: !!rootState.user.publisher.short_description,
      long_description: !!rootState.user.publisher.long_description,
      platform_audience:
        rootState.user.publisher.target_audience &&
        rootState.user.publisher.target_audience.length > 0 &&
        rootState.user.publisher.media &&
        rootState.user.publisher.media.length > 0 &&
        rootState.user.publisher.topics &&
        rootState.user.publisher.topics.length > 0,
      reach: !!rootState.user.publisher.total_min_reach
    };
    return state.tasks
      .map(t => {
        t.done = done[t.name];
        return t;
      })
      .sort((a, b) => b.completed - a.completed);
  },
  profileCompletionPercentage(_state, getters) {
    return getters.tasks.filter(t => t.done).length / getters.tasks.length;
  },
  finishedProfileCompletionTasks(_state, getters) {
    const done = getters.tasks.filter(t => t.done);
    if (done.length > 2) {
      return done.slice(done.length - 2, done.length);
    } else {
      return done.slice(0, 2);
    }
  },
  nextProfileCompletionTasks(_state, getters) {
    return getters.tasks
      .filter(t => !t.done)
      .slice(0, 4 - getters.finishedProfileCompletionTasks.length);
  }
};

export default {
  state,
  getters
};
