import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/services/jwt.service";
import { REFRESH_ACCESS_TOKEN, LOGOUT } from "@/store/auth.module";
import store from "@/store";
import config from "@/config";
import simpleCache from "@/lib/simple-cache";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  // Stores the 401 interceptor position so that it can be later ejected when needed
  _401interceptor: null,

  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = config.api.baseURL;
  },

  getDefaultHeaders({ authorization = true, extraHeaders = {} } = {}) {
    const token = JwtService.getToken();
    const headers = { ...extraHeaders };
    if (authorization && token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    return headers;
  },

  /**
   * Send the GET HTTP request
   * @param {string} resource
   * @param {string} slug
   * @param {{ params?: Object, authorization?: boolean }=} options
   * @returns {*}
   */
  get(resource, slug = "", { params, authorization = true } = {}) {
    return Vue.axios({
      method: "get",
      url: `${resource}/${slug}`,
      headers: this.getDefaultHeaders({ authorization }),
      params
    });
  },

  /**
   * Performs a GET while preventing this GET to happen again
   * for a certain time
   * @param {string} url Url to get
   * @param {number} timeout Timeout in miliseconds
   * @returns {Promise<any>}
   */
  getCached(url, timeout) {
    // If there is data (or a promise) in the cache, return it
    if (simpleCache.get(url)) {
      return simpleCache.get(url);
    }
    // Call getting data
    const promise = this.get(url);
    // As soon as the promise completes store the data
    promise.then(r => {
      simpleCache.set(url, r, timeout);
    });
    // For now store the promise for subsequent calls to getCached
    simpleCache.set(url, promise, timeout);
    // And return the promise to the callee
    return promise;
  },

  /**
   * Send a POST HTTP request
   * @param {string} url URL to post to
   * @param {Object} data Post body
   * @returns {Promise<any>}
   */
  post(url, data, { authorization = true, extraHeaders = {} } = {}) {
    return Vue.axios({
      method: "post",
      url,
      data,
      headers: this.getDefaultHeaders({ authorization, extraHeaders })
    });
  },

  /**
   * Send the PATCH HTTP request
   * @param {string} url
   * @param {Object} data
   * @param {{ authorization: boolean }} options
   * @returns {Promise<any>}
   */
  patch(url, data, { authorization = true } = { authorization: true }) {
    return Vue.axios({
      method: "patch",
      url,
      data,
      headers: this.getDefaultHeaders({ authorization })
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {Promise<any>}
   */
  put(url, data, { authorization = true } = {}) {
    return Vue.axios({
      method: "put",
      url,
      data,
      headers: this.getDefaultHeaders({ authorization })
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(url, { authorization = true } = {}) {
    return Vue.axios({
      method: "delete",
      url,
      headers: this.getDefaultHeaders({ authorization })
    });
  },

  /**
   * Send the OPTIONS HTTP request
   * @param resource
   * @returns {*}
   */
  options(url, { authorization = true } = {}) {
    return Vue.axios({
      method: "options",
      url,
      headers: this.getDefaultHeaders({ authorization })
    });
  },

  /**
   * Perform a custom Axios request
   * NOTE: doesn't be default include the Authorization header
   **/
  request(data) {
    return Vue.axios(data);
  },

  /**
   * Intercepts every API response and checks if the status
   * is 401. If it is, checks if the 401 occured on the refresh
   * call itself (to prevent infinite loops). Then refreshes
   * the token and retries the failed request and returns the
   * response to caller.
   */
  mount401Interceptor() {
    this._401interceptor = Vue.axios.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        if (error.request.status == 401) {
          if (error.config.url.includes("/refresh/")) {
            console.error(
              "Got 401 on refresh token request",
              error.response.data
            );
            // Refresh token has failed. Logout the user
            store.dispatch(LOGOUT);
            throw error;
          }

          try {
            // Refresh the access token
            await store.dispatch(REFRESH_ACCESS_TOKEN);
          } catch (err) {
            // Refresh has failed, log out
            console.error("Access token refresh failed:", err);
            store.dispatch(LOGOUT);
            return;
          }

          // Override Authorization header with new access token
          const headers = error.config.headers;
          if (headers.Authorization && this.getDefaultHeaders().Authorization) {
            headers.Authorization = this.getDefaultHeaders().Authorization;
          }

          return this.request({
            method: error.config.method,
            url: error.config.url,
            data: error.config.data,
            params: error.config.params,
            headers
          });
        }
        // If error was not 401 just reject as is
        throw error;
      }
    );
  },

  /**
   * Remove 401 interceptor to refresh token when we actually want
   * to logout a user.
   */
  unmount401Interceptor() {
    // Eject the interceptor
    Vue.axios.interceptors.response.eject(this._401interceptor);
  }
};

export default ApiService;
