import { trim } from "./string";

export function cleanUrl(url) {
  if (!url) return "";
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    url = "https://" + url;
  }
  const u = new URL(url);
  const path = trim(u.pathname, "/");
  return (
    u.host.replace("www.", "") +
    (path ? `/${path}` : "") +
    (u.search ? u.search : "")
  );
}

/**
 * Convert url to lowercase + adds http:// in front of url if the
 * protocol is not defined
 * @param {string} url
 * @returns {string}
 */
export function fixUrl(url) {
  let result = url.toLowerCase();
  if (!result.startsWith("http://") && !result.startsWith("https://")) {
    return `http://${result}`;
  }
  return result;
}

/**
 * Checks if a url is a valid url (with optional http/https protocol)
 * Make sure the url is in lowercase as capitals will be invalidaded
 * @param {string} string
 * @returns {boolean}
 */
export function isValidUrl(string) {
  var res = string.match(
    /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/]*)$/g
  );
  return res !== null;
}
