export default {
  api: {
    baseURL: "http://localhost:8000/api"
  },
  typesense: {
    storeCollection: "local_Store",
    publisherCollection: "local_Publisher",
    productCollection: "local_Product",
    apiKey: "6qZIxpWk9Fgxy6HGd7JrGSXXd5nKYLZ6" // store-dashboard local search key
  },
  impactbytesUrls: {
    app: "http://localhost:8080"
  },
  jotform: {
    brandInfoFormId: "231583077748365",
    multiBrandInfoFormId: "223383466774062",
    materialFormId: "223053718495056",
    supplierFormId: "222981805960059"
  }
};
