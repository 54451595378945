// From: https://stackoverflow.com/a/55292366/1515053

function hasSubstringAt(str, substr, pos) {
  var idx = 0,
    len = substr.length;

  for (var max = str.length; idx < len; ++idx) {
    if (pos + idx >= max || str[pos + idx] != substr[idx]) break;
  }

  return idx === len;
}

/**
 * Trims a certain character from a string
 * @param {string} str
 * @param {string} ch
 * @returns {string}
 */
export function trim(str, ch) {
  let start = 0;
  let end = str.length;

  while (start < end && str[start] === ch) {
    ++start;
  }

  while (end > start && str[end - 1] === ch) {
    --end;
  }

  return start > 0 || end < str.length ? str.substring(start, end) : str;
}

/**
 * Trims a certain word from a string
 * @param {string} str 
 * @param {string} word 
 * @returns 
 */
export function trimWord(str, word) {
  var start = 0,
    end = str.length,
    len = word.length;

  while (start < end && hasSubstringAt(str, word, start)) start += word.length;

  while (end > start && hasSubstringAt(str, word, end - len))
    end -= word.length;

  return start > 0 || end < str.length ? str.substring(start, end) : str;
}

/**
 * Shortens a text based on a max length, cutting the text on whole words
 * @param {string} text
 * @param {number} length
 * @returns {string}
 */
export function truncate(text, length) {
  if (text.length <= length) {
    return text;
  }
  const subString = text.substr(0, length - 1);
  return subString.substr(0, subString.lastIndexOf(" ")) + "…";
}
