import kebabCase from "lodash/kebabCase";
import ApiService from "./api.service";

/** @type {{[key: string]: StoreType }} */
export const STORE_TYPES = {
  SINGLE_BRAND: 0,
  SINGLE_BRAND_AND_MORE: 1,
  MULTI_BRAND: 2
};

/**
 * Get a single store
 * @param {number} id
 * @returns {Promise<Store>}
 */
export async function getStore(id) {
  /** @type {{ data: Store }} */
  const { data: store } = await ApiService.get(`ib/public/store/${id}`);
  return store;
}

/**
 * Create a new store for this (store) user
 * @param {{ store_name: string, store_url: string, store_category: Array, store_type: StoreType, affiliate: { email: string }, framework: Number, other_framework: string }} param0
 * @returns {Promise<Store>}
 */
export async function createMyStore({
  store_name,
  store_url,
  affiliate,
  store_category,
  store_type,
  framework,
  other_framework
}) {
  const { data: result } = await ApiService.post("ib/forshops/store/", {
    store_name,
    store_url,
    affiliate,
    store_category,
    store_type,
    framework,
    other_framework,
    referrer: window.localStorage.getItem("referrer")
  });
  return result;
}

/**
 * Get information for user's store.
 * @returns {Promise<Store>}
 */
export async function getMyStore() {
  /** @type {{ data: { results: Store[] } }} */
  const {
    data: { results: stores }
  } = await ApiService.get("ib/forshops/store");
  return stores[0];
}

/**
 * Options required to edit store profile (for dropdowns and so on)
 * @returns {Promise<{framework: Array}>} Field options
 */
export async function getFieldOptions() {
  /** @type {{ data: { options: { framework: Array }}}} */
  const {
    data: { options }
  } = await ApiService.get("ib/forshops/store/field-options");
  return options;
}

/**
 * Returns analytics data for number of sales and sales revenue for a certain
 * time range
 * @param {{ start: Date, range: Number, unit: "day"|"week"|"month", publisher?: Number }} params
 * @returns {Promise<{ sales: Number[], revenue: Number[], xAxis: string[]}>}
 */
export async function getSalesAnalytics({ start, range, unit, publisher }) {
  const {
    data: { sales, revenue, xAxis }
  } = await ApiService.get("ib/forshops/plots/sales", "", {
    params: {
      // dd-mm-yyyy
      start: `${start.getDate()}-${
        start.getMonth() + 1
      }-${start.getFullYear()}`,
      range,
      unit,
      publisher
    },
    authorization: true
  });
  return { sales, revenue, xAxis };
}

/**
 * Returns analytics data for number of clicks and sales revenue for a certain
 * time range
 * @param {{ start: Date, range: Number, unit: "day"|"week"|"month", publisher?: Number }} params
 * @returns {Promise<{ clicks: Number[], xAxis: string[]}>}
 */
export async function getClickAnalytics({ start, range, unit, publisher }) {
  const {
    data: { clicks, xAxis }
  } = await ApiService.get("ib/forshops/plots/clicks", "", {
    params: {
      // dd-mm-yyyy
      start: `${start.getDate()}-${
        start.getMonth() + 1
      }-${start.getFullYear()}`,
      range,
      unit,
      publisher
    },
    authorization: true
  });
  return { clicks, xAxis };
}

/**
 * Returns analytics data for number of clicks and sales revenue for a certain
 * time range
 * @param {{ start: Date, range: Number, unit: "day"|"week"|"month" }} params
 * @returns {Promise<{ views: Number[], xAxis: string[]}>}
 */
export async function getViewAnalytics({ start, range, unit }) {
  const {
    data: { views, xAxis }
  } = await ApiService.get("ib/forshops/plots/views", "", {
    params: {
      // dd-mm-yyyy
      start: `${start.getDate()}-${
        start.getMonth() + 1
      }-${start.getFullYear()}`,
      range,
      unit
    },
    authorization: true
  });
  return { views, xAxis };
}


/**
 * @param {MembershipPlanPlan} membership
 * @param {MembershipPlanPaymentTerm} term
 * @param {Boolean} no_start_date
 * @return {Promise<Store>} update store
 */
export async function createMembershipPlan(membership, term, no_start_date = false) {
  const { data: result } = await ApiService.post("ib/forshops/membership/", {
    membership,
    term,
    no_start_date
  });
  return result;
}

/**
 *
 * @returns {Promise<ExternalFormResponse[]>}
 */
export async function getExternalFormResponses() {
  const {
    data: { results: responses }
  } = await ApiService.get("ib/forshops/form-responses");
  return responses;
}

/**
 *
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<ExternalFormResponse>}
 */
export async function updateExternalFormResponse(id, data) {
  /** @type {{ data: ExternalFormResponse }} */
  const { data: response } = await ApiService.patch(
    `ib/forshops/form-responses/${id}/`,
    data
  );
  return response;
}

/**
 * Update server on status of impact analysis
 * @returns {Promise<Store>} updated store
 */
export async function submitImpactAnalysis() {
  const { data: store } = await ApiService.post(
    "ib/forshops/store/submit-impact-analysis/"
  );
  return store;
}

/**
 * Adds projectcece.com as hostname in case the url doesn't have a hostname
 * @param {string} url
 * @returns {string}
 */
export function prefixStoreLogoUrl(url) {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return "https://projectcece.com" + url;
  }
  return url;
}

/**
 * Re-route a typesense request over our API so that an API key with more permissions can be used
 * (if the user fulfill the authentication/permission requirements)
 * @param {Object} data
 * @returns
 */
export function typesenseReroute(data) {
  return ApiService.post("ib/forshops/typesense/", data);
}

/**
 * Get a single publisher public information
 * @param {number} id
 * @returns {Promise<publisher>}
 */
export async function getPublicPublisher(id) {
  /** @type {{ data: Publisher }} */
  const { data: publisher } = await ApiService.get(`ib/public/publisher/${id}`);
  return publisher;
}

/**
 * Gets a single publisher
 * @param {string} id
 * @returns {Promise<Publisher>}
 */
export async function getPublisher(id) {
  const { data: publisher } = await ApiService.get(
    `ib/forshops/publisher/${id}`,
    ""
  );
  return publisher;
}

/**
 * Gets publishers having some sort of deal with the current store
 * @returns {Promise<Publisher[]>}
 */
export async function getMyPublishers() {
  const {
    data: { results: publishers }
  } = await ApiService.get("ib/forshops/mypublishers");
  return publishers;
}

/**
 * Generates a url to the pubilsher page on the webshop side of the application
 * @param {string} id Publisher ID
 * @param {string} name Publisher name
 * @returns {string}
 */
export function getPublisherUrl(id, name) {
  return `/w/affiliate/publishers/${id}-${kebabCase(name)}/`;
}

/**
 * Get a single ecommerce framework for a store
 * @param {number} id
 * @returns {Promise<EcommerceFramework>}
 */
export async function getFramework(id) {
  /** @type {{ data: EcommerceFramework }} */
  const { data: framework } = await ApiService.get(`ib/forshops/framework/${id}`);
  return framework;
}

/**
 * @param {number} id
 * @return {Dict} update store
 */
export function requestFairproCheck(id) {
  return ApiService.post(`ib/forshops/store/${id}/request-fairpro-check/`);
}

/**
 * Check if a valid (Molie) mandate exists for store automatic payments
 * @returns {Promise<Dict[]>}
 */
export async function hasValidPaymentMandates() {
  const { data: mandates } = await ApiService.get(`ib/forshops/store/has-mandate`);
  return mandates;
}

/**
 * Get redirect url to setup Mollie payment to create payment mandate
 * @returns {Promise<string>}
 */
export async function getPaymentMandateRedirectUrl() {
  const { data: url } = await ApiService.get(`ib/forshops/store/setup-pay`);
  return url;
}

export async function deletePaymentMethodMandate(mandate_id) {
  const { data: result } = await ApiService.post("ib/forshops/store/delete-payment-method/", {
    mandate_id
  });
  return result;
}

export  function uploadImageContent(formdata) {
  return ApiService.post("ib/forshops/store/upload-content-image/", formdata, { extraHeaders: { "Content-Type": "multipart/form-data" } });
}

export async function getStoreProfileForPublisher() {
  const { data: content } = await ApiService.get("ib/forshops/store/store-profile");
  return content;
}

export async function updateStoreProfileForPublisher(updatedContent) {
  const { data: content } = await ApiService.post("ib/forshops/store/store-profile/", {content: updatedContent});
  return content;
}