import ApiService from "@/services/api.service";

export const UserService = {
  /*
   * Create a new publisher user
   */
  createPublisherUser({ email, firstName, lastName, password }) {
    return ApiService.post("ib/forpublishers/createuser/", {
      email,
      first_name: firstName,
      last_name: lastName,
      password
    });
  },
  /*
   * Create a new store user
   */
  createStoreUser({ email, firstName, lastName, password }) {
    return ApiService.post("ib/forshops/createuser/", {
      email,
      first_name: firstName,
      last_name: lastName,
      password
    });
  },
  /*
   * Call /api/dashboard/user/me/ to get the signed-in user's data.
   */
  me() {
    return ApiService.get("dashboard", "user/me/");
  },
  /*
   * Call /api/dashboard/user/me/ and PATCH data which must be updated.
   */
  updateMe({ firstName, lastName }) {
    if (!firstName) {
      throw new Error('NO_FIRSTNAME');
    }
    return ApiService.patch("dashboard/user/me/", { first_name: firstName, last_name: lastName });
  },
  confirmEmailAddress({ uid, token }) {
    return ApiService.get(
      `/ib/forpublishers/createuser/activate/${uid}`,
      `?token=${token}`
    );
  },
  resendEmailConfirmation() {
    return ApiService.post("/dashboard/user/me/resend-email-confirmation/");
  },
  /*
   * Request a password reset link per email
   *
   * POST data most contain fields:
   * - email : String : The user's email address.
   */
  requestPasswordResetEmail(data) {
    return ApiService.post("/v1/auth/password-reset/", data);
  },

  /*
   * Validate password reset UUID (uidb64) & token.
   */
  validatePwResetUidb64Token(uidb64, token) {
    return ApiService.get("/v1/auth/password-reset", `${uidb64}/${token}/`);
  },

  /*
   * Reset password
   *
   * POST data must contain fields:
   * - new_password_1 : String : The new password
   * - new_password_2 : String : The new password (to verify no typos etc.)
   */
  resetPassword(uidb64, token, data) {
    console.log(data);
    return ApiService.post(`/v1/auth/password-reset/${uidb64}/${token}/`, data);
  },
  /*
   * Change a user's password.
   *
   * Provided data mut include the following fields:
   * - email : String : The user's email
   * - old_password : String : The current active password
   * - new_password_1 : String : The new password
   * - new_password_2 : String : The new password (to verify no typos etc.)
   */
  passwordChange(data) {
    return ApiService.post("v1/auth/password_change/", data);
  }
};
