import merge from "lodash/merge";
import shared from "./shared.config.js";
import production from "./production.config.js";
import local from "./local.config.js";
import staging from "./staging.config.js";
import test from "./test.config.js";

let name = process.env.VUE_APP_CONFIG;
let config;

console.log(`Load ${name} config`);

if (name === "production") {
  config = merge(shared, production);
} else if (name === "staging") {
  config = merge(shared, staging);
} else if (name === "test") {
  config = merge(shared, test);
} else if (name === "local") {
  config = merge(shared, local);
} else {
  throw new Error(
    `VUE_APP_CONFIG has an unknown value, should be 'production', 'staging', 'test' or 'local', but is '${name}'`
  );
}

export default config;
