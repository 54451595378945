import i18n from "@/plugins/vue-i18n";

export const routes = [
  {
    path: "onboarding/",
    name: "publisher-onboarding-user",
    component: () =>
      import(
        /* webpackChunkName: "publisher-onboarding-user" */
        "@/pages/onboarding/User.vue"
      ),
    meta: {
      title: i18n.t("PUBLISHER.ONBOARDING.PAGE_TITLE")
    }
  },
  {
    path: "onboarding/publisher/",
    name: "publisher-onboarding-publisher",
    component: () =>
      import(
        /* webpackChunkName: "publisher-onboarding-publisher" */
        "@/pages/publisher/onboarding/Publisher.vue"
      ),
    meta: {
      title: i18n.t("PUBLISHER.ONBOARDING.PAGE_TITLE")
    }
  },
  {
    path: "dashboard/",
    name: "publisher-dashboard",
    component: () =>
      import(
        /* webpackChunkName: "publisher-dashboard" */
        "@/pages/publisher/dashboard/Dashboard.vue"
      ),
    meta: {
      title: i18n.t("PUBLISHER_DASHBOARD.PAGE_TITLE")
    }
  },
  {
    path: "analytics/",
    name: "publisher-analytics",
    component: () =>
      import(
        /* webpackChunkName: "publisher-analytics" */
        "@/pages/publisher/analytics/Analytics.vue"
      ),
    meta: {
      title: i18n.t("PUBLISHER.ANALYTICS.PAGE_TITLE")
    }
  },
  {
    path: "settings/",
    name: "publisher-settings",
    redirect: "settings/account",
    component: {
      render: c => c("router-view")
    },
    meta: {
      title: i18n.t("PAGE_TITLES.SETTINGS")
    },
    children: [
      {
        path: "account/",
        name: "publisher-settings-account",
        component: () =>
          import(
            /* webpackChunkName: "publisher-settings-user" */
            "@/pages/publisher/settings/User.vue"
          ),
        meta: {
          title: i18n.t("PAGE_TITLES.PUBLISHER_USER_SETTINGS")
        }
      },
      {
        path: "publisher/",
        name: "publisher-settings-publisher",
        component: () =>
          import(
            /* webpackChunkName: "publisher-settings-publisher" */
            "@/pages/publisher/settings/publisher/Publisher.vue"
          ),
        meta: {
          title: i18n.t("PUBLISHER.AFFILIATE_SETTINGS.PAGE_TITLE")
        }
      },
      {
        path: "finance/",
        name: "publisher-settings-finance",
        component: () =>
          import(
            /* webpackChunkName: "publisher-settings-finance" */
            "@/pages/publisher/settings/Finance.vue"
          ),
        meta: {
          title: i18n.t("PUBLISHER.AFFILIATE_SETTINGS.PAGE_TITLE")
        }
      },
      {
        path: "integrations/",
        name: "publisher-settings-integrations",
        component: () =>
          import(
            /* webpackChunkName: "publisher-settings-integrations" */
            "@/pages/publisher/settings/Integrations.vue"
          ),
        meta: {
          title: i18n.t("PUBLISHER.INTEGRATIONS_SETTINGS.PAGE_TITLE")
        }
      },
      {
        path: "user/confirm-email/:uid/:token/",
        name: "publisher-confirm-email",
        component: () =>
          import(
            /* webpackChunkName: "publisher-confirm-email" */
            "@/pages/publisher/settings/ConfirmEmail.vue"
          ),
        meta: {
          title: i18n.t("PAGE_TITLES.PUBLISHER_CONFIRM_EMAIL")
        }
      },
      {
        path: "user/confirm-email/",
        name: "publisher-settings-confirm-email",
        component: () =>
          import("@/pages/publisher/settings/ConfirmEmailSettings.vue"),
        meta: {
          title: i18n.t("PAGE_TITLES.PUBLISHER_CONFIRM_EMAIL")
        }
      },
      {
        path: "change-password/",
        name: "publisher-change-password",
        // TODO: don't load webshop password change page
        component: () => import("@/pages/webshop/settings/PasswordChange.vue"),
        meta: {
          title: i18n.t("PAGE_TITLES.PW_CHANGE")
        }
      }
    ]
  },
  {
    path: "finance/",
    name: "publisher-finance",
    redirect: "finance/transactions",
    component: {
      render: c => c("router-view")
    },
    children: [
      {
        path: "transactions/",
        name: "publisher-finance-transactions",
        component: () =>
          import(
            /* webpackChunkName: "publisher-finance-transactions" */
            "@/pages/publisher/finance/Transactions.vue"
          ),
        meta: {
          title: i18n.t("PUBLISHER_TRANSACTIONS.PAGE_TITLE")
        }
      },
      {
        path: "payments/",
        name: "publisher-finance-payments",
        component: () =>
          import(
            /* webpackChunkName: "coming-soon" */
            "@/pages/publisher/finance/Payments.vue"
          ),
        meta: {
          title: i18n.t("PAGE_TITLES.PUBLISHER_FINANCE_PAYMENTS")
        }
      }
    ]
  },
  {
    path: "affiliate/",
    name: "publisher-affiliate",
    redirect: "affiliate/my-advertisers/",
    component: {
      render: c => c("router-view")
    },
    children: [
      {
        path: "my-advertisers/",
        name: "publisher-my-advertiser-overview",
        component: () =>
          import(
            /* webpackChunkName: "publisher-my-advertisers" */
            "@/pages/publisher/advertisers/my-advertisers/MyAdvertisers.vue"
          ),
        meta: {
          title: i18n.t("PUBLISHER_MY_ADVERTISERS.PAGE_TITLE")
        }
      },
      {
        path: "my-advertisers/invite",
        name: "publisher-my-advertisers-invite",
        component: () =>
          import("@/pages/publisher/advertisers/my-advertisers/Invite.vue"),
        meta: {
          title: i18n.t("PUBLISHER_MY_ADVERTISERS.PAGE_TITLE")
        }
      },
      {
        path: "advertisers/",
        name: "publisher-advertiser-overview",
        component: () =>
          import(
            /* webpackChunkName: "publisher-advertisers" */
            "@/pages/publisher/advertisers/advertisers/Overview.vue"
          ),
        meta: {
          title: i18n.t("PUBLISHER_ADVERTISERS_OVERVIEW.PAGE_TITLE")
        }
      },
      {
        path: "advertisers/project-cece",
        name: "publisher-advertiser-project-cece",
        component: () =>
          import(
            /* webpackChunkName: "publisher-advertiser-cece" */
            "@/pages/publisher/advertisers/advertisers/ProjectCece.vue"
          ),
        meta: {
          title: i18n.t("PUBLISHER_ADVERTISER_DETAIL.PAGE_TITLE")
        }
      },
      {
        path: "advertisers/:slug",
        name: "publisher-advertiser-detail",
        component: () =>
          import(
            /* webpackChunkName: "publisher-advertiser" */
            "@/pages/publisher/advertisers/advertisers/Detail.vue"
          ),
        meta: {
          title: i18n.t("PUBLISHER_ADVERTISER_DETAIL.PAGE_TITLE")
        }
      },
      {
        path: "products/",
        name: "publisher-products",
        component: () =>
          import(
            /* webpackChunkName: "publisher-products" */
            "@/pages/publisher/advertisers/products/Overview.vue"
          ),
        meta: {
          title: i18n.t("PUBLISHER.PRODUCTS.PAGE_TITLE")
        }
      },
      {
        path: "products/:slug",
        name: "publisher-product-detail",
        component: () =>
          import(
            /* webpackChunkName: "publisher-product" */
            "@/pages/publisher/advertisers/products/Detail.vue"
          ),
        meta: {
          title: i18n.t("PUBLISHER.PRODUCT_DETAIL.PAGE_TITLE")
        }
      },
      {
        path: "invite-link/",
        name: "publisher-my-advertisers-invite",
        component: () =>
          import("@/pages/publisher/advertisers/my-advertisers/Invite.vue"),
        meta: {
          title: i18n.t("PUBLISHER_INVITE_LINK.PAGE_TITLE")
        }
      }
    ]
  },
  {
    path: "faq/",
    name: "publisher-faq",
    component: () => import("@/pages/publisher/faq/Faq.vue"),
    meta: {
      title: i18n.t("PUBLISHER.FAQ.PAGE_TITLE")
    }
  },
  {
    path: "*",
    name: "publisher-404",
    component: () =>
      import(
        /* webpackChunkName: "publisher-404" */
        "@/pages/publisher/404.vue"
      ),
    meta: {
      title: "404 Page Not Found" // TODO: translate
    }
  }
];
