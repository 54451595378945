const ID_TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";

let TOKEN_CACHE;

export const getToken = () => {
  if (TOKEN_CACHE) {
    return TOKEN_CACHE;
  }
  TOKEN_CACHE = window.localStorage.getItem(ID_TOKEN_KEY);
  return TOKEN_CACHE;
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
  TOKEN_CACHE = token;
};

export const saveRefreshToken = refreshToken => {
  window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
};

export const destroyTokens = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(REFRESH_TOKEN_KEY);
  TOKEN_CACHE = null;
};

export const getRefreshToken = () => {
  return window.localStorage.getItem(REFRESH_TOKEN_KEY);
};

export default {
  getToken,
  saveToken,
  saveRefreshToken,
  destroyTokens,
  getRefreshToken
};
